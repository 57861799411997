import React from "react";
import { Route, Routes } from "react-router-dom";
import BasketHome from "./BasketHome";
import LeaguesBasket from "./Leagues/LeaguesBasket";
import BasketMatches from "./Matches/BasketMatches";
import BasketFutureMatch from "./Matches/BasketFutureMatch";
import BasketResult from "./otherFiles/BasketResult";
import BasketPoint from "./otherFiles/BasketPoint";
import BasketTodayMatch from "./Matches/BasketTodayMatch";
import BasketOverview from "./Matches/BasketOverview";
import BasketOlderMatch from "./Matches/BasketOlderMatch";
import BasketInfo from "./otherFiles/BasketInfo";
import BaskLiveScore from "./LiveScores/BaskLiveScore";
import BasketSummary from "./otherFiles/BasketSummary";
import Error from "../Error";

const BasketIndex = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<BasketHome />} />
        <Route path={`/:dated`} element={<BasketHome />} />
        <Route path="/league/*" element={<LeaguesBasket />} />
        <Route path="/match/*" element={<BasketMatches />} />
        <Route path="/league/future_match" element={<BasketFutureMatch />} />
        <Route path="/league/result/*" element={<BasketResult />} />
        <Route path="/league/today/*" element={<BasketTodayMatch />} />
        <Route path="/summary" element={<BasketSummary />} />
        <Route path="/table" element={<BasketPoint />} />
        <Route path="/info" element={<BasketInfo />} />
        <Route path="/live" element={<BaskLiveScore />} />
        <Route path="/overview" element={<BasketOverview />} />
        <Route path="/league/past_match" element={<BasketOlderMatch />} />
        {/* <Route path="*" element={<Error />} /> */}
      </Routes>
    </div>
  );
};

export default BasketIndex;
