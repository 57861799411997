import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HockFutureMatch from "./HockFutureMatch";
import ReactCountryFlag from "react-country-flag";

const HockOverview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { match } = location.state || {};

  const handleClick = (match) => {
    navigate("/hockey/leagues/todaymatch", { state: { match } });
  };

  const handleRoute = (match) => {
    navigate("/hockey/leagues/result", { state: { match } });
  };

  const convertTimestamp = (timestamp) => {
    const dateObject = new Date(timestamp * 1000);
    return dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const convertTimestampToDate = (timestamp) => {
    const dateObject = new Date(timestamp * 1000);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return dateObject.toLocaleDateString(undefined, options);
  };

  const groupByTournament = (matches) => {
    return matches.reduce((groups, match) => {
      const tournament = match.tournament.name;
      if (!groups[tournament]) {
        groups[tournament] = [];
      }
      groups[tournament].push(match);
      return groups;
    }, {});
  };

  const sortTournamentsAlphabetically = (tournamentGroups) => {
    return Object.keys(tournamentGroups).sort((a, b) => a.localeCompare(b));
  };

  const upcomingMatches = Array.isArray(match)
    ? match.filter((matchItem) => matchItem.status.code === 0)
    : [];
  const completedMatches = Array.isArray(match)
    ? match.filter((matchItem) => matchItem.status.code === 100)
    : [];

  const sortedUpcomingMatches = upcomingMatches.sort(
    (a, b) => a.startTimestamp - b.startTimestamp
  );

  const limitedUpcomingMatches = sortedUpcomingMatches.slice(0, 2);

  const groupedCompletedMatches = groupByTournament(completedMatches);

  const sortedCompletedTournaments = sortTournamentsAlphabetically(
    groupedCompletedMatches
  );

  return (
    <div className="p-3">
      <div>
        <h6 className="mx-3">Fixtures</h6>
        {limitedUpcomingMatches.map((game, index) => (
          <div key={index}>
            <button
              className="card-body text-white btn w-100 d-flex align-items-center mx-2 p-3"
              onClick={() => handleClick(game)}
            >
              <span className="fsize">
                <p className="fsize">{convertTimestamp(game.startTimestamp)}</p>
                {game.status.description}
              </span>
              <div>
                <div className="d-flex justify-content-between mt-2">
                  <section className="mx-2 text-start">
                    <div className="d-flex gap-2">
                      <ReactCountryFlag
                        countryCode={game?.homeTeam?.country?.alpha2}
                        svg
                        style={{
                          width: "1.3em",
                          height: "1.3em",
                        }}
                        title={game?.homeTeam?.country?.alpha2}
                      />
                      <p>{game.homeTeam.name}</p>
                    </div>
                    <div className="d-flex gap-2 mt-2">
                      <ReactCountryFlag
                        countryCode={game?.awayTeam?.country?.alpha2}
                        svg
                        style={{
                          width: "1.3em",
                          height: "1.3em",
                        }}
                        title={game?.awayTeam?.country?.alpha2}
                      />
                      <p>{game.awayTeam.name}</p>
                    </div>
                  </section>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>

      <div className="mt-4">
      <h6 className="mx-3">Results</h6>
        {sortedCompletedTournaments.map((tournament, tIndex) => (
          <div key={tIndex}>
            <h6 className="fsize p-2">{tournament}</h6>
            {groupedCompletedMatches[tournament].map((game, index) => (
              <div key={index}>
                <div>
                  <button
                    className="card-body text-white btn w-100 align-items-center mx-2 p-3"
                    onClick={() => handleRoute(game)}
                  >
                    <div className="d-flex justify-content-between mt-2">
                      <section className="mx-2 text-start">
                        <div className="d-flex gap-2">
                          <ReactCountryFlag
                            countryCode={game?.homeTeam?.country?.alpha2}
                            svg
                            style={{
                              width: "1.3em",
                              height: "1.3em",
                            }}
                            title={game?.homeTeam?.country?.alpha2}
                          />
                          <p>{game.homeTeam.name}</p>
                        </div>
                        <div className="d-flex gap-2 mt-2">
                          <ReactCountryFlag
                            countryCode={game?.awayTeam?.country?.alpha2}
                            svg
                            style={{
                              width: "1.3em",
                              height: "1.3em",
                            }}
                            title={game?.awayTeam?.country?.alpha2}
                          />
                          <p>{game.awayTeam.name}</p>
                        </div>
                      </section>
                      <section className="mx-4">
                        <p>{game.homeScore.display}</p>
                        <p>{game.awayScore.display}</p>
                      </section>
                    </div>
                    <span className="fsize">
                      {convertTimestampToDate(game.startTimestamp)}{" "}
                      {game.status.description}
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HockOverview;
