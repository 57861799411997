// import React, { createContext, useContext, useState, useEffect } from "react";
// import axios from "axios";
// import debounce from "lodash/debounce";
// import { KeyProvider } from "../../KeyProvider";

// const CricketContext = createContext();

// export const useCricket = () => useContext(CricketContext);

// export const CricketProvider = ({ children, selectedDate }) => {

//   const [resData, setResData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const debouncedGetSportsData = debounce(async (date) => {
//     setIsLoading(true);
//     if (!date) return;
//     try {
//       setIsLoading(true);
//       const defaultUrl =
//         // "https://os-sports-perform.p.rapidapi.com/v1/events/schedule/date";
//         "https://os-sports-perform.p.rapidapi.com/v1/events/schedule/category";
//       const defaultParams = {
//         date: date.toISOString().split('T')[0],
//         sport_id: "62",
//         category_id: 1343
//       };

//       const options = {
//         headers: {
//           "X-RapidAPI-Key": KeyProvider ,
//           "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
//         },
//         params: defaultParams,
//       };

//       const response = await axios.get(defaultUrl, options);
//       setIsLoading(true);
//       console.log("Cricket Date Data:", response?.data);

//       setResData(response?.data?.data || []);
//       setIsLoading(false);
//       setIsLoading(true);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setIsLoading(false);
//     } finally {
//       setIsLoading(false);
//     }
//   }, 500);

//   useEffect(() => {
//     debouncedGetSportsData(selectedDate);
//     return () => {
//       debouncedGetSportsData.cancel();
//     };
//   }, [selectedDate]);

//   return (
//     <CricketContext.Provider value={{ selectedDate, resData, isLoading }}>
//       {children}
//     </CricketContext.Provider>
//   );
// };
///////////////////////////////////////////////////////////////////////////////////////////

// import React, { createContext, useContext, useState, useEffect } from "react";
// import axios from "axios";
// import { debounce } from "lodash";
// import { KeyProvider } from "../../KeyProvider";

// const CricketContext = createContext();

// export const useCricket = () => useContext(CricketContext);

// export const CricketProvider = ({ children, selectedDate }) => {
//   const [resData, setResData] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   console.log(categories);

//   const fetchCategoryIds = async () => {
//     try {
//       const response = await axios.get("https://os-sports-perform.p.rapidapi.com/v1/categories?sport_id=62", {
//         headers: {
//           "X-RapidAPI-Key": KeyProvider,
//           "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
//         },
//       });
//       const fetchedCategories = response?.data?.data || [];
//       setCategories(fetchedCategories);
//     } catch (error) {
//       console.error("Error fetching category IDs:", error);
//     }
//   };

//   const fetchSportsDataForBatch = async (date, batch) => {
//     const data = await Promise.all(batch.map(async (category) => {
//       const defaultUrl = "https://os-sports-perform.p.rapidapi.com/v1/events/schedule/category";
//       const defaultParams = {
//         date: date.toISOString().split('T')[0],
//         sport_id: "62",
//         category_id: category.id,
//       };

//       const options = {
//         headers: {
//           "X-RapidAPI-Key": KeyProvider,
//           "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
//         },
//         params: defaultParams,
//       };

//       try {
//         const response = await axios.get(defaultUrl, options);
//         return response?.data?.data || [];
//       } catch (error) {
//         console.error(`Error fetching data for category ${category.id}:`, error);
//         return [];
//       }
//     }));
//     return data.flat();
//   };

//   const fetchAllSportsData = async (date, categoryIds) => {
//     setIsLoading(true);
//     const batchSize = 4;
//     let combinedData = [];

//     for (let i = 0; i < categoryIds.length; i += batchSize) {
//       const batch = categoryIds.slice(i, i + batchSize);
//       const batchData = await fetchSportsDataForBatch(date, batch);
//       combinedData = [...combinedData, ...batchData];

//       if (i + batchSize < categoryIds.length) {
//         await new Promise((resolve) => setTimeout(resolve, 1000));
//       }
//     }

//     setResData(combinedData);
//     setIsLoading(false);
//     console.log("Cricket Date Data:", combinedData);
//   };

//   const debouncedFetchAllSportsData = debounce(fetchAllSportsData, 500);

//   useEffect(() => {
//     fetchCategoryIds();
//   }, []);

//   useEffect(() => {
//     if (categories.length) {
//       debouncedFetchAllSportsData(selectedDate, categories);
//     }
//     return () => {
//       debouncedFetchAllSportsData.cancel();
//     };
//   }, [selectedDate, categories]);

//   return (
//     <CricketContext.Provider value={{ selectedDate, resData, isLoading, categories }}>
//       {children}
//     </CricketContext.Provider>
//   );
// };

/////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { KeyProvider } from "../../KeyProvider";

const CricketContext = createContext();

export const useCricket = () => useContext(CricketContext);

export const CricketProvider = ({ children, selectedDate }) => {
  const [resData, setResData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);



  const fetchCategoryIds = async () => {
    try {
      const response = await axios.get("https://os-sports-perform.p.rapidapi.com/v1/categories?sport_id=62", {
        headers: {
          "X-RapidAPI-Key": KeyProvider,
          "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
        },
      });
      const fetchedCategories = response?.data?.data || [];
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Error fetching category IDs:", error);
    }
  };

  const fetchSportsDataForBatch = async (date, batch) => {
    setIsLoading(true)
    const data = await Promise.all(batch.map(async (category) => {
      const defaultUrl = "https://os-sports-perform.p.rapidapi.com/v1/events/schedule/category";
      const defaultParams = {
        date: date.toISOString().split('T')[0],
        sport_id: "62",
        category_id: category.id,
      };

      const options = {
        headers: {
          "X-RapidAPI-Key": KeyProvider,
          "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
        },
        params: defaultParams,
      };

      try {
        const response = await axios.get(defaultUrl, options);
        setIsLoading(false)
        return response?.data?.data || [];
      } catch (error) {
        setIsLoading(false)
        console.error(`Error fetching data for category ${category.id}:`, error);
        return [];
      }
    }));
    return data.flat();
  };

  const fetchAllSportsData = async (date, categoryIds) => {
    setIsLoading(true);
    const batchSize = 4;
    let combinedData = [];

    for (let i = 0; i < categoryIds.length; i += batchSize) {
      const batch = categoryIds.slice(i, i + batchSize);
      const batchData = await fetchSportsDataForBatch(date, batch);
      combinedData = [...combinedData, ...batchData];



      setResData(prevData => [...prevData, ...batchData]);

      if (i + batchSize < categoryIds.length) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }

    setIsLoading(false);
   
  };

 

  const debouncedFetchAllSportsData = debounce(fetchAllSportsData, 500);

  useEffect(() => {
    fetchCategoryIds();
  }, []);

  useEffect(() => {
    if (categories.length) {
      debouncedFetchAllSportsData(selectedDate, categories);
    }
    return () => {
      debouncedFetchAllSportsData.cancel();
    };
  }, [selectedDate, categories]);

  return (
    <CricketContext.Provider value={{ selectedDate, resData, isLoading, categories }}>
      {children}
    </CricketContext.Provider>
  );
};
