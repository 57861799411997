import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { KeyProvider } from "../../KeyProvider";

const ScoreCard = () => {
  const [resData, setResData] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const date = new Date().toISOString().split("T")[0];
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    const getSportsData = async () => {
      const defaultUrl =
        "https://os-sports-perform.p.rapidapi.com/v1/events/innings";
      const defaultParams = {
        event_id: match.id,
      };

      const options = {
        headers: {
          "X-RapidAPI-Key": KeyProvider,
          "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.get(defaultUrl, {
          params: defaultParams,
          headers: options.headers,
        });

        setResData(response.data.data);
        setIsLoading(false);
        setActiveTab(response.data.data[0]?.id || "");
      } catch (error) {
        console.error("Error fetching Scorecard data:", error);
      }
    };

    getSportsData();
  }, [date]);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {isLoading ? (
        <div
          className="spinner-grow"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      ) : (
        <div className="small-font my-4 mx-4">
          <section className="d-flex gap-4">
            {resData.map((game) => (
              <button
                key={game.id}
                className={`btn btn-sm p-2 ${
                  activeTab === game.id ? "active" : ""
                }`}
                onClick={() => toggleTab(game.id)}
              >
                {game.battingTeam.name}
              </button>
            ))}
          </section>
          {activeTab && (
            <section>
              <div>
                <section className="mx-4 my-2">
                  <table className="table table-dark">
                    <thead>
                      <tr>
                        <th scope="row-shrink">Batsman</th>
                        <th>R</th>
                        <th>B</th>
                        <th>4S</th>
                        <th>6S</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resData
                        .find((game) => game.id === activeTab)
                        ?.battingLine?.map((player, index) => (
                          <tr key={index}>
                            <td>
                              {player.player.name}
                              <div className="fsize">
                                {player.wicketTypeId == 3 ? (
                                  <p>
                                    b. {player?.wicketBowler?.name}, c.{" "}
                                    {player?.wicketCatchName}
                                  </p>
                                ) : (
                                  <p>
                                    {player.wicketTypeName} {""}{" "}
                                    {player.wicketBowlerName}
                                  </p>
                                )}
                              </div>
                            </td>
                            <td className="text-secondary">{player.score}</td>
                            <td className="text-secondary">{player.balls}</td>
                            <td className="text-secondary">{player.s4}</td>
                            <td className="text-secondary">{player.s6}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </section>
              </div>
              <div className="m-4">
                <div>STATS</div>
                <div className="border rounded-3 p-2">
                  <div>
                    Total:{" "}
                    {resData.find((game) => game.id === activeTab)?.score}/
                    <span>
                      {resData.find((game) => game.id === activeTab)?.wickets}
                    </span>
                  </div>
                  <div>
                    Overs:{" "}
                    {resData.find((game) => game.id === activeTab)?.overs}
                  </div>
                  <div className="d-flex gap-4">
                    <p>
                      Extras:{" "}
                      {resData.find((game) => game.id === activeTab)?.extra}
                    </p>
                    <p>
                      B: {resData.find((game) => game.id === activeTab)?.bye}
                    </p>
                    <p>
                      LB:{" "}
                      {resData.find((game) => game.id === activeTab)?.legBye}
                    </p>
                    <p>
                      NB:{" "}
                      {resData.find((game) => game.id === activeTab)?.noBall}
                    </p>
                    <p>
                      WD: {resData.find((game) => game.id === activeTab)?.wide}
                    </p>
                    <p>
                      P:{" "}
                      {resData.find((game) => game.id === activeTab)?.penalty}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <section className="mx-4">
                  <table className="table table-dark">
                    <thead>
                      <tr>
                        <th>BOWLING</th>
                        <th>O</th>
                        <th>M</th>
                        <th>R</th>
                        <th>W</th>
                        <th>NB</th>
                        <th>WD</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resData
                        .find((game) => game.id === activeTab)
                        ?.bowlingLine?.map((bowler, index) => (
                          <tr key={index}>
                            <td className="text-secondary">
                              {bowler.playerName}
                            </td>
                            <td className="text-secondary">{bowler.over}</td>
                            <td className="text-secondary">{bowler.maiden}</td>
                            <td className="text-secondary">{bowler.run}</td>
                            <td className="text-secondary">{bowler.wicket}</td>
                            <td className="text-secondary">{bowler.noBall}</td>
                            <td className="text-secondary">{bowler.wide}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </section>
              </div>
            </section>
          )}
        </div>
      )}
    </>
  );
};

export default ScoreCard;
