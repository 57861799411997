import React from "react";
import HockeyHome from "./HockeyHome";
import Leagues from "./Leagues/Leagues";
import HockMatches from "./Matches/HockMatches";
import HockFutureMatch from "./Matches/HockFutureMatch";
import HockResult from "./otherFiles/HockResult";
import HockTodayMatch from "./Matches/HockTodayMatch";
import { Route, Routes } from "react-router-dom";
import FootOverview from "../Football/Matches/FootOverview";
import HockOlderMatch from "./Matches/HockOlderMatch";
import HockLiveScore from "./LiveScores/HockLiveScore";
import PointTable from "./otherFiles/PointTable";
import Error from "../Error";

const HockeyIndex = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HockeyHome />} />
        <Route path={`/:dated`} element={<HockeyHome />} />
        <Route path="/leagues/*" element={<Leagues />} />
        <Route path="/match" element={<HockMatches />} />
        <Route path="/live" element={<HockLiveScore />} />
        <Route path="/future_match" element={<HockFutureMatch />} />
        <Route path="/past_match" element={<HockOlderMatch />} />
        <Route path="/leagues/result/*" element={<HockResult />} />
        <Route path="/leagues/todaymatch/*" element={<HockTodayMatch />} />
        <Route path="/overview" element={<FootOverview />} />
        <Route path="/table" element={<PointTable />} />
        {/* <Route path="*" element={<Error />} /> */}
      </Routes>
    </div>
  );
};

export default HockeyIndex;
