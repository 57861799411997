import React from "react";
import { useLocation } from "react-router-dom";

const BasketInfo = () => {
  const location = useLocation();
  const { match } = location.state || {};

  const convertTimestampToDate = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const dateString = dateObject.toISOString().split("T")[0];
    const timeString = dateObject.toTimeString().split(" ")[0];
    return `${dateString} ${timeString}`;
  };

  return (
    <div>
      <p className="fsize m-3 p-3">
        Match Starts - {convertTimestampToDate(match?.startTimestamp)}
      </p>
    </div>
  );
};

export default BasketInfo;
