import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HockOverview from "../Matches/HockOverview";
import HockMatches from "../Matches/HockMatches";

const Leagues = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("la") || "Overview"
  );

  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    localStorage.setItem("la", activeTab);

    return () => {
      localStorage.removeItem("la");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="card-title d-flex gap-2 p-3">
        <Link
          to="/hockey/leagues/overview"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Overview" ? "active" : ""
          }`}
          onClick={() => handleClick("Overview")}
        >
          Overview
        </Link>
        <Link
          to="/hockey/leagues/match"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "HockMatch" ? "active" : ""
          }`}
          onClick={() => handleClick("HockMatch")}
        >
          Matches
        </Link>
      </div>
      {activeTab === "Overview" && <HockOverview />}
      {activeTab === "HockMatch" && <HockMatches />}
      <br />
    </div>
  );
};

export default Leagues;
