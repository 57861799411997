import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BaseOverview from "../Matches/BaseOverview";
import BaseMatches from "../Matches/BaseMatches";

const BaseLeagues = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("baskLeague") || "Overview"
  );
  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    localStorage.setItem("baseLeague", activeTab);

    return () => {
      localStorage.removeItem("baseLeague");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="card-title d-flex gap-2 px-2">
        <Link
          to="/baseball/league/overview"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Overview" ? "active" : ""
          }`}
          onClick={() => handleClick("Overview")}
        >
          Overview
        </Link>
        <Link
          to="/baseball/league/match"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "BaseMatch" ? "active" : ""
          }`}
          onClick={() => handleClick("BaseMatch")}
        >
          Matches
        </Link>
      </div>
      {activeTab === "Overview" && <BaseOverview />}
      {activeTab === "BaseMatch" && <BaseMatches />}
      <br />
    </div>
  );
};

export default BaseLeagues;
