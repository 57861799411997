import React from 'react'

const Favourites = () => {
  return (
    <div>
        <div className='d-flex gap-2'>
            <div>Matches</div>
            <div>Competitions</div>
            <div>Teams</div>
        </div>
    </div>
  )
}

export default Favourites