import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import { KeyProvider } from "../../../KeyProvider";

const VolleyLive = () => {
  const navigate = useNavigate();
  const [resData, setResData] = useState([]);

  const DateOnly = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { month: "short", day: "2-digit" };
    const dateString = date.toLocaleDateString("en-US", options);
    return dateString;
  };

  const convertTimestampToTimeOnly = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const timeString = dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return timeString;
  };

  const date = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const getSportsData = async () => {
      const defaultUrl = 'https://os-sports-perform.p.rapidapi.com/v1/events/schedule/live';
      const defaultParams = {
        date: date,
        sport_id: '23'
      };
      const options = {
        headers: {
          'X-RapidAPI-Key': KeyProvider,
          'X-RapidAPI-Host': 'os-sports-perform.p.rapidapi.com'
        }
      };
      try {
        const response = await axios.get(defaultUrl, {
          params: defaultParams,
          headers: options.headers
        });
        setResData(response.data.data);
      } catch (error) {
        console.error("Error fetching sports data:", error);
      }
    };
    getSportsData();
  }, [date]);

  const handleClick = (match) => {
    navigate("/volleyball/league/result", { state: { match } });
  };

  return (
    <div className="small-font my-4 mx-4">
      <h6>Live Score</h6>
      {resData.length > 0 ? (
        resData.map((match, index) => (
          <button
            className="btn w-100 card-body p-3"
            key={index}
            onClick={() => handleClick(match)}
          >
            <div className="d-flex justify-content-between align-items-center gap-2 m-1 p-3">
              <div className="d-flex align-items-center">
                <section className="text-start">
                  <p className="fsize">{DateOnly(match.startTimestamp)}</p>
                  <p className="fsize">
                    {convertTimestampToTimeOnly(match.startTimestamp)}
                  </p>
                </section>
                <section className="mx-2">
                  <div className="d-flex gap-2">
                    {match.homeTeam?.country?.alpha2 ? (
                      <ReactCountryFlag
                        countryCode={match.homeTeam.country.alpha2}
                        svg
                        style={{
                          width: "1.5em",
                          height: "1.5em",
                        }}
                        title={match.homeTeam.country.alpha2}
                      />
                    ) : (
                      <img
                        // src={matchFlag}
                        alt="flag"
                        style={{ width: "1.5em", height: "1.5em" }}
                      />
                    )}
                    <h6>{match.homeTeam.name}</h6>
                  </div>
                  <div className="d-flex gap-2">
                    {match.awayTeam?.country?.alpha2 ? (
                      <ReactCountryFlag
                        countryCode={match.awayTeam.country.alpha2}
                        svg
                        style={{
                          width: "1.5em",
                          height: "1.5em",
                        }}
                        title={match.awayTeam.country.alpha2}
                      />
                    ) : (
                      <img
                        // src={matchFlag}
                        alt="flag"
                        style={{ width: "1.5em", height: "1.5em" }}
                      />
                    )}
                    <h6>{match.awayTeam.name}</h6>
                  </div>
                </section>
              </div>
              <section className="mx-4 fsize">
                <h6>{match.homeScore.display}</h6>
                <h6>{match.awayScore.display}</h6>
              </section>
            </div>
          </button>
        ))
      ) : (
        <p>There are no live events at the moment</p>
      )}
    </div>
  );
};

export default VolleyLive;
