import React from "react";
import ReactCountryFlag from "react-country-flag";
import { useLocation, useNavigate } from "react-router-dom";

const HandballFutureMatch = () => {
  const location = useLocation();
  const { match } = location.state || {};
  const navigate = useNavigate();

  const handleClick = (match) => {
    navigate("/handball/league/today", { state: { match } });
  };

  const convertTimestamp = (timestamp) => {
    const dateObject = new Date(timestamp * 1000);
    return dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const convertTimestampToDate = (timestamp) => {
    const dateObject = new Date(timestamp * 1000);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return dateObject.toLocaleDateString(undefined, options);
  };

  const futuredMatch = match.filter((match) => match.status.code === 0);

  return (
    <div>
      {futuredMatch && futuredMatch.length > 0 ? (
        futuredMatch.map((match, index) => (
          <div key={index} className="mx-2">
            <button
              className="card-body p-2 btn w-100 text-white d-flex align-items-center mb-2"
              onClick={() => handleClick(match)}
            >
              <section className="fsize">
                <span className="fsize">
                  <p className="fsize">
                    {convertTimestamp(match.startTimestamp)}
                  </p>
                  {match?.status?.description}
                </span>
              </section>
              <section className="mx-4">
                <div className="d-flex gap-2">
                  <ReactCountryFlag
                    countryCode={match?.homeTeam?.country?.alpha2}
                    svg
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                    title={match?.homeTeam?.country?.alpha3}
                  />
                  <h6>{match?.homeTeam?.name}</h6>
                </div>
                <div className="d-flex gap-2">
                  <ReactCountryFlag
                    countryCode={match?.awayTeam?.country?.alpha2}
                    svg
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                    title={match?.awayTeam?.country?.alpha3}
                  />
                  <h6>{match?.awayTeam?.name}</h6>
                </div>
              </section>
            </button>
          </div>
        ))
      ) : (
        <p>There is No Matches Today</p>
      )}
    </div>
  );
};

export default HandballFutureMatch;
