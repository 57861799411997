// import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import Overview from "../Matches/Overview";
// import MatchHome from "../Matches/MatchHome";

// const LeagueComponent = () => {
//   const [activeTab, setActiveTab] = useState(
//     localStorage.getItem("activeTab") || "Overview"
//   );
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { match, element } = location.state || {};

//   useEffect(() => {
//     localStorage.setItem("activeTab", activeTab);
//     return localStorage.removeItem("activeTab");
//   }, [activeTab]);
  
//   if (match.tournament.uniqueTournament.category.id === element.category.id) return;


//   const handleClick = (tab) => {
//     setActiveTab(tab);
//     switch (tab) {
//       case "Overview":
//         navigate("/cricket/league/overview", { state: { match } });
//         break;
//       case "Matches":
//         navigate("/cricket/league/matches", { state: { match } });
//     }
//   };

//   return (
//     <div>
//       <div className="card-title d-flex gap-2 px-2">
//         <button
//           className={`link-eff btn btn-sm p-2 ${
//             activeTab === "Overview" ? "active" : ""
//           }`}
//           onClick={() => handleClick("Overview")}
//         >
//           Overview
//         </button>
//         <button
//           className={`link-eff btn btn-sm p-2 ${
//             activeTab === "Matches" ? "active" : ""
//           }`}
//           onClick={() => handleClick("Matches")}
//         >
//           Matches
//         </button>
//       </div>
//       <div>
//         {activeTab === "Overview" && <Overview games={match} />}
//         {activeTab === "Matches" && <MatchHome games={match} />}
//       </div>
//       <br />
//     </div>
//   );
// };

// export default LeagueComponent;
///////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Overview from "../Matches/Overview";
import MatchHome from "../Matches/MatchHome";

const LeagueComponent = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "Overview"
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { match} = location.state || {};

  // console.log(uniqueCategoryId, match);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
    return () => {
      localStorage.removeItem("activeTab");
    };
  }, [activeTab]);

  // Check if the received uniqueCategoryId matches the one in match object
  // if (match?.tournament?.uniqueTournament?.category?.id !== uniqueCategoryId) {
  //   return <div>No data found</div>;
  // }

  const handleClick = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "Overview":
        navigate("/cricket/league/overview", { state: { match } });
        break;
      case "Matches":
        navigate("/cricket/league/matches", { state: { match } });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="card-title d-flex gap-2 px-2">
        <button
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Overview" ? "active" : ""
          }`}
          onClick={() => handleClick("Overview")}
        >
          Overview
        </button>
        <button
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Matches" ? "active" : ""
          }`}
          onClick={() => handleClick("Matches")}
        >
          Matches
        </button>
      </div>
      <div>
        {activeTab === "Overview" && <Overview />}
        {activeTab === "Matches" && <MatchHome />}
      </div>
      <br />
    </div>
  );
};

export default LeagueComponent;
