import React from "react";
import countryFlag from "../../../assets/India_Flag.png";
import { useLocation } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";

const FootFutureMatch = () => {
  const location = useLocation();
  const { match } = location.state || {};

  const convertTimestampToDate = (timestamp) => {
    const dateObject = new Date(timestamp * 1000);
    return dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const upcomingMatches = Array.isArray(match)
    ? match.filter((matchItem) => matchItem.status.code === 0)
    : [];

  return (
    <div className="p-3">
      {upcomingMatches.map((match, index) => (
        <button
          key={index}
          className="btn card-body d-flex align-items-center w-100 p-3"
        >
          <div>
            <span className="fsize">
              {convertTimestampToDate(match.startTimestamp)}
            </span>
          </div>
          <div key={index} className="text-white align-items-center">
            <div className="mx-2">
              <div className="d-flex gap-2">
                <ReactCountryFlag
                  countryCode={match?.homeTeam?.country?.alpha2}
                  svg
                  style={{
                    width: "1.5em",
                    height: "1.5em",
                  }}
                  title={match?.homeTeam?.country?.alpha2}
                />
                <p>{match.homeTeam.name}</p>
              </div>
              <div className="d-flex gap-2">
                <ReactCountryFlag
                  countryCode={match?.awayTeam?.country?.alpha2}
                  svg
                  style={{
                    width: "1.5em",
                    height: "1.5em",
                  }}
                  title={match?.awayTeam?.country?.alpha2}
                />
                <p>{match.awayTeam.name}</p>
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
};

export default FootFutureMatch;
