import React, { useEffect, useState, memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cricket from "../assets/cricket.png";
import football from "../assets/football.png";
import hockey from "../assets/hockey.png";
import basket from "../assets/basketball.png";
import volley from "../assets/volleyball.png";
import baseball from "../assets/baseball.png";
import handball from "../assets/handball.png";

const Games = memo(({ setGameId }) => {
  const [activeLink, setActiveLink] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathMap = [
      { path: "/cricket", id: "cricket" },
      { path: "/football", id: "football" },
      { path: "/hockey", id: "hockey" },
      { path: "/basketball", id: "basketball" },
      { path: "/volleyball", id: "volleyball" },
      { path: "/baseball", id: "baseball" },
      { path: "/handball", id: "handball" },
      { path: "/", id: "cricket" },
    ];

    const active =
      pathMap.find((item) => pathname.startsWith(item.path))?.id || "cricket";
    setActiveLink(active);
  }, [pathname]);

  const handleClick = (gameName) => {
    if (gameName === "cricket") {
      setGameId("62");
    } else if (gameName === "football") {
      setGameId("1");
    } else if (gameName === "hockey") {
      setGameId("4");
    } else if (gameName === "basketball") {
      setGameId("2");
    } else if (gameName === "volleyball") {
      setGameId("23");
    } else if (gameName === "baseball") {
      setGameId("64");
    } else if (gameName === "handball") {
      setGameId("6");
    } else {
      setGameId("62");
    }

    setActiveLink(gameName);

    navigate(`/${gameName === "cricket" ? "" : gameName}`);
  };

  return (
    <nav
      className="container navbar navbar-expand-lg bg-body-tertiary mt-3"
      data-bs-theme="dark"
    >
      <div className="container-fluid">
        <div>
          <button
            className={"btn text-white p-2"}
            onClick={() => handleClick("cricket")}
          >
            LIVE SCORE
          </button>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {[
              { name: "cricket", icon: cricket },
              { name: "football", icon: football },
              { name: "hockey", icon: hockey },
              { name: "basketball", icon: basket },
              { name: "volleyball", icon: volley },
              { name: "baseball", icon: baseball },
              { name: "handball", icon: handball },
            ].map((game) => (
              <li key={game.name} className="nav-item">
                <button className="btn" onClick={() => handleClick(game.name)}>
                  <img
                    src={game.icon}
                    className="gameIcon"
                    alt={`${game.name} icon`}
                  />
                  <Link
                    to={`/${game.name === "cricket" ? "" : game.name}`}
                    className={`link-eff p-2 rounded-2 ${
                      activeLink === game.name ? "active" : ""
                    }`}
                  >
                    {game.name.charAt(0).toUpperCase() + game.name.slice(1)}
                  </Link>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
});

export default Games;
