// import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom';
// import countryFlag from "../../assets/basketball.png";
// import loader from "../../assets/Loader.gif";
// import { useBasketball } from './BasketballContext';
// import ReactCountryFlag from 'react-country-flag';

// const BasketHome = () => {
//   const { selectedDate, resData, isLoading } = useBasketball();
//   const navigate = useNavigate();

//   const convertTimestampToDate = (timestamp) => {
//     const milliseconds = timestamp * 1000;
//     const dateObject = new Date(milliseconds);
//     const dateString = dateObject.toISOString().split("T")[0];
//     const timeString = dateObject.toTimeString().split(" ")[0];
//     return `${dateString} ${timeString}`;
//   };

//   const convertTimestampToDateOnly = (timestamp) => {
//     const date = new Date(timestamp * 1000);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');
//     const dateString = `${year}-${month}-${day}`;
//     return dateString;
//   }
  
//   const DateOnly = (timestamp) => {
//     const date = new Date(timestamp * 1000);
//     const options = { month: 'short', day: '2-digit' };
//     const dateString = date.toLocaleDateString('en-US', options);
//     return dateString;
//   }
  
//   const convertTimestampToTimeOnly = (timestamp) => {
//     const milliseconds = timestamp * 1000;
//     const dateObject = new Date(milliseconds);
//     const timeString = dateObject.toLocaleTimeString([], {
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true
//     });
//     return timeString;
//   };

//   const handleClick = (match) => {
//     if (match.status.code === 0) {
//       navigate("/basketball/league/today", { state: { match } });
//     } else if (match.status.code === 21 || match.status.code === 100) {
//       navigate("/basketball/league/result", { state: { match } });
//     }
//   };

//   const handleRoute = (games) => {
//     const match = games;
//     console.log(match);
//     navigate("/basketball/league", {state: {match}});
//   };
  
//   const gamesByTournament = {};

//   resData.forEach((game) => {
//     const tournamentId = game.tournament?.uniqueTournament?.id;
//     if (tournamentId) {
//       if (!gamesByTournament[tournamentId]) {
//         gamesByTournament[tournamentId] = [];
//       }
//       gamesByTournament[tournamentId].push(game);
//     }
//   });

//   return (
//     <div>
//       {isLoading ? (
//         <img src={loader} alt="loader" className="img-fluid" />
//       ) : (
//         Object.entries(gamesByTournament).map(([tournamentId, games]) => {
//           const filteredGames = games.filter((game) => game.status.code !== 21);

//           const tournamentGamesForSelectedDate = filteredGames.filter(
//             (game) =>
//               convertTimestampToDateOnly(game.startTimestamp) ===
//               selectedDate.toLocaleDateString('en-CA')
//           );

//           if (tournamentGamesForSelectedDate.length === 0) return null;

//           const tournamentData = {
//             name: tournamentGamesForSelectedDate[0].tournament?.name,
//             country:
//               tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
//                 ?.category?.country?.name,
//             countryName:
//               tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
//                 ?.category?.name,
//             flag: tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
//               ?.category?.country?.alpha2,
//             homeflag:
//               tournamentGamesForSelectedDate[0].homeTeam?.country?.alpha2,
//             awayflag:
//               tournamentGamesForSelectedDate[0].awayTeam?.country?.alpha2,
//             games: tournamentGamesForSelectedDate,
//           };

//           return (
//             <div key={tournamentId} className='px-4'>
//               <div className="d-flex">
//                 <button
//                   onClick={() => handleRoute(games)}
//                   className="btn btn-secondary w-100 d-flex align-items-center gap-2 mx-2 p-1"
//                   style={{ backgroundColor: "#111" }}
//                 >
//                   {tournamentData.flag ? (
//                     <ReactCountryFlag
//                       countryCode={tournamentData.flag}
//                       svg
//                       style={{
//                         width: "1.5em",
//                         height: "1.5em",
//                       }}
//                       title={tournamentData.flag}
//                     />
//                   ) : (
//                     <img
//                       src={countryFlag}
//                       alt="flag"
//                       style={{ width: "1.5em", height: "1.5em" }}
//                     />
//                   )}

//                   <div className="mx-2 text-start">
//                     <p>{tournamentData.countryName}</p>
//                     <p>{tournamentData.name}</p>
//                   </div>
//                 </button>
//               </div>
//               {tournamentGamesForSelectedDate.map((game, index) => (
//                 <div key={index} className="my-2">
//                     <button
//                       className="card-body text-white btn w-100 align-items-center p-3"
//                       onClick={() => handleClick(game)}
//                     >
//                       <div className="d-flex justify-content-between align-items-center gap-2 m-1">
//                         <div className="d-flex align-items-center text-start">
//                         <section>
//                           <p className="fsize">{DateOnly(game.startTimestamp)}</p>
//                           <p className="fsize">{convertTimestampToTimeOnly(game.startTimestamp)}</p>
//                         </section>
//                         <section className="mx-2 text-start">
//                           <div className="d-flex gap-2">
//                           {game.homeTeam?.country?.alpha2 ? (
//                               <ReactCountryFlag
//                                 countryCode={game.homeTeam.country.alpha2}
//                                 svg
//                                 style={{
//                                   width: "1.5em",
//                                   height: "1.5em",
//                                 }}
//                                 title={game.homeTeam.country.alpha2}
//                               />
//                             ) : (
//                               <img
//                                 src={countryFlag}
//                                 alt="flag"
//                                 style={{ width: "1.5em", height: "1.5em" }}
//                               />
//                             )}
//                             <h6>{game.homeTeam.name}</h6>
//                             </div>
//                             <div className="d-flex gap-2">
//                             {game.awayTeam?.country?.alpha2 ? (
//                               <ReactCountryFlag
//                                 countryCode={game.awayTeam.country.alpha2}
//                                 svg
//                                 style={{
//                                   width: "1.5em",
//                                   height: "1.5em",
//                                 }}
//                                 title={game.awayTeam.country.alpha2}
//                               />
//                             ) : (
//                               <img
//                                 src={countryFlag}
//                                 alt="flag"
//                                 style={{ width: "1.5em", height: "1.5em" }}
//                               />
//                             )}
//                             <h6>{game.awayTeam.name}</h6>
//                             </div>
//                         </section>
//                         </div>
//                         <section className="mx-4 fsize">
//                           <h6>{game.homeScore.display}</h6>
//                           <h6>{game.awayScore.display}</h6>
//                         </section>
//                       </div>
//                       <p className="my-0">{game.note}</p>
//                     </button>
//                 </div>
//               ))}
//             </div>
//           );
//         })
//       )}
//     </div>
//   );
// }

// export default BasketHome

///////////////////////////////////////////////////////////////

import React from "react";
import { useNavigate } from "react-router-dom";
import countryFlag from "../../assets/basketball.png";
import loader from "../../assets/Loader.gif";
import { useBasketball } from "./BasketballContext";
import ReactCountryFlag from "react-country-flag";

const BasketHome = () => {
  const { selectedDate, resData, isLoading } = useBasketball();
  const navigate = useNavigate();

  const convertTimestampToDate = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const dateString = dateObject.toISOString().split("T")[0];
    const timeString = dateObject.toTimeString().split(" ")[0];
    return `${dateString} ${timeString}`;
  };

  const convertTimestampToDateOnly = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const dateString = `${year}-${month}-${day}`;
    return dateString;
  }

  const DateOnly = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { month: 'short', day: '2-digit' };
    const dateString = date.toLocaleDateString('en-US', options);
    return dateString;
  }

  const convertTimestampToTimeOnly = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const timeString = dateObject.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    return timeString;
  };

  const handleClick = (match) => {
    if (match.status.code === 0) {
      navigate("/basketball/league/today", { state: { match } });
    } else if (match.status.code === 21 || match.status.code === 100) {
      navigate("/basketball/league/result", { state: { match } });
    }
  };

  const handleRoute = (games) => {
    const match = games;
    navigate("/basketball/league", { state: { match } });
  };

  const gamesByTournament = {};

  resData.forEach((game) => {
    const tournamentId = game.tournament?.uniqueTournament?.id;
    if (tournamentId) {
      if (!gamesByTournament[tournamentId]) {
        gamesByTournament[tournamentId] = [];
      }
      gamesByTournament[tournamentId].push(game);
    }
  });

  return (
    <div>
      {isLoading && resData.length === 0 ? (
        <img src={loader} alt="loader" className="img-fluid" />
      ) : (
        Object.entries(gamesByTournament).map(([tournamentId, games]) => {
          const filteredGames = games.filter((game) => game.status.code !== 21);

          const tournamentGamesForSelectedDate = filteredGames.filter(
            (game) =>
              convertTimestampToDateOnly(game.startTimestamp) ===
              selectedDate.toLocaleDateString('en-CA')
          );

          if (tournamentGamesForSelectedDate.length === 0) return null;

          const tournamentData = {
            name: tournamentGamesForSelectedDate[0].tournament?.name,
            country:
              tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
                ?.category?.country?.name,
            countryName:
              tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
                ?.category?.name,
            flag: tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
              ?.category?.country?.alpha2,
            homeflag:
              tournamentGamesForSelectedDate[0].homeTeam?.country?.alpha2,
            awayflag:
              tournamentGamesForSelectedDate[0].awayTeam?.country?.alpha2,
            games: tournamentGamesForSelectedDate,
          };

          return (
            <div key={tournamentId} className='px-4'>
              <div className="d-flex">
                <button
                  onClick={() => handleRoute(games)}
                  className="btn btn-secondary w-100 d-flex align-items-center gap-2 mx-2 p-1"
                  style={{ backgroundColor: "#111" }}
                >
                  {tournamentData.flag ? (
                    <ReactCountryFlag
                      countryCode={tournamentData.flag}
                      svg
                      style={{
                        width: "1.5em",
                        height: "1.5em",
                      }}
                      title={tournamentData.flag}
                    />
                  ) : (
                    <img
                      src={countryFlag}
                      alt="flag"
                      style={{ width: "1.5em", height: "1.5em" }}
                    />
                  )}

                  <div className="mx-2 text-start">
                    <p>{tournamentData.countryName}</p>
                    <p>{tournamentData.name}</p>
                  </div>
                </button>
              </div>
              {tournamentGamesForSelectedDate.map((game, index) => (
                <div key={index} className="my-2">
                    <button
                      className="card-body text-white btn w-100 align-items-center p-3"
                      onClick={() => handleClick(game)}
                    >
                      <div className="d-flex justify-content-between align-items-center gap-2 m-1">
                        <div className="d-flex align-items-center text-start">
                        <section>
                          <p className="fsize">{DateOnly(game.startTimestamp)}</p>
                          <p className="fsize">{convertTimestampToTimeOnly(game.startTimestamp)}</p>
                        </section>
                        <section className="mx-2 text-start">
                          <div className="d-flex gap-2">
                          {game.homeTeam?.country?.alpha2 ? (
                              <ReactCountryFlag
                                countryCode={game.homeTeam.country.alpha2}
                                svg
                                style={{
                                  width: "1.5em",
                                  height: "1.5em",
                                }}
                                title={game.homeTeam.country.alpha2}
                              />
                            ) : (
                              <img
                                src={countryFlag}
                                alt="flag"
                                style={{ width: "1.5em", height: "1.5em" }}
                              />
                            )}
                            <h6>{game.homeTeam.name}</h6>
                            </div>
                            <div className="d-flex gap-2">
                            {game.awayTeam?.country?.alpha2 ? (
                              <ReactCountryFlag
                                countryCode={game.awayTeam.country.alpha2}
                                svg
                                style={{
                                  width: "1.5em",
                                  height: "1.5em",
                                }}
                                title={game.awayTeam.country.alpha2}
                              />
                            ) : (
                              <img
                                src={countryFlag}
                                alt="flag"
                                style={{ width: "1.5em", height: "1.5em" }}
                              />
                            )}
                            <h6>{game.awayTeam.name}</h6>
                            </div>
                        </section>
                        </div>
                        <section className="mx-4 fsize">
                          <h6>{game.homeScore.display}</h6>
                          <h6>{game.awayScore.display}</h6>
                        </section>
                      </div>
                      <p className="my-0">{game.note}</p>
                    </button>
                </div>
              ))}
            </div>
          );
        })
      )}
    </div>
  );
}

export default BasketHome;
