import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";

const VolleyFutureMatch = () => {
  const location = useLocation();
  const { match } = location.state || {};
  const navigate = useNavigate();

  const handleClick = (match) => {
    navigate("/basketball/league/today", { state: { match } });
  };

  const convertTimestamp = (timestamp) => {
    const dateObject = new Date(timestamp * 1000);
    return dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const convertTimestampToDate = (timestamp) => {
    const dateObject = new Date(timestamp * 1000);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return dateObject.toLocaleDateString(undefined, options);
  };

  const futuredMatch = match.filter((match) => match.status.code === 0);

  return (
    <div className="px-3">
      {futuredMatch && futuredMatch.length > 0 ? (
        futuredMatch.map((match, index) => (
          <div key={index}>
            <button
              className="card-body text-white btn w-100 d-flex align-items-center mx-2 p-3"
              onClick={() => handleClick(match)}
            >
              <span className="fsize">
                <p className="fsize">{convertTimestamp(match.startTimestamp)}</p>
                {match.status.description}
              </span>
              <div>
                <div className="d-flex justify-content-between mt-2">
                  <section className="mx-2 text-start">
                    <div className="d-flex gap-2">
                      <ReactCountryFlag
                        countryCode={match?.homeTeam?.country?.alpha2}
                        svg
                        style={{
                          width: "1.3em",
                          height: "1.3em",
                        }}
                        title={match?.homeTeam?.country?.alpha2}
                      />
                      <p>{match.homeTeam.name}</p>
                    </div>
                    <div className="d-flex gap-2 mt-2">
                      <ReactCountryFlag
                        countryCode={match?.awayTeam?.country?.alpha2}
                        svg
                        style={{
                          width: "1.3em",
                          height: "1.3em",
                        }}
                        title={match?.awayTeam?.country?.alpha2}
                      />
                      <p>{match.awayTeam.name}</p>
                    </div>
                  </section>
                </div>
              </div>
            </button>
          </div>
        ))
      ) : (
        <p>There is No Matches Today</p>
      )}
    </div>
  );
};

export default VolleyFutureMatch;
