import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Overview = () => {
  const location = useLocation();
  const { match } = location.state || {};

  const [matches, setMatches] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!match) return;

    const today = new Date();
    const startOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ).getTime();
    const endOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59,
      999
    ).getTime();

    const todayMatches = match.filter((match) => {
      const matchTime = match.startTimestamp * 1000;
      return matchTime >= startOfDay && matchTime <= endOfDay;
    });

    setMatches(todayMatches);
  }, [match]);

  const handleClick = (clickedMatch, match) => {
    navigate("/cricket/league/today", { state: { match, clickedMatch } });
  };

  const convertTimestampToDate = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const dateString = dateObject.toISOString().split("T")[0];
    const timeString = dateObject.toTimeString().split(" ")[0];
    return `${dateString} ${timeString}`;
  };

  const upcomingMatches = matches.filter((match) => match.status.code === 0);
  const liveMatches = matches.filter((match) => match.status.code === 21);
  const completedMatches = matches.filter((match) => match.status.code === 100);

  return (
    <div className="mt-4">
      {/* Upcoming Matches */}
      {upcomingMatches.length > 0 && (
        <>
          <h6 className="mx-3">Fixtures</h6>
          {upcomingMatches.map((match, index) => (
            <button
              key={index}
              className="btn w-100 card-body text-white my-3"
              onClick={() => handleClick(match.id, match)}
            >
              <div className="mx-3 text-start">
                <span className="fsize">{match.tournament.name}</span>
                <br />
                <p className="fsize">
                  {convertTimestampToDate(match.startTimestamp)} -{" "}
                  {match.status.description}
                </p>
                <div className="d-flex justify-content-between mx-3 mt-3">
                  <section>
                    <h6>{match.awayTeam.name}</h6>
                    <h6>{match.homeTeam.name}</h6>
                  </section>
                </div>
              </div>
            </button>
          ))}
        </>
      )}

      {/* Live Matches */}
      {liveMatches.length > 0 && (
        <>
          {liveMatches.map((live, index) => (
            <div
              key={index}
              className="card-body text-white cursor"
              onClick={() => handleClick(live.id)}
            >
              <span className="fsize m-2">{live?.tournament.name}</span>
              <br />
              <div className="d-flex justify-content-between m-2">
                <section>
                  <h6>{live.awayTeam.name}</h6>
                  <h6>{live.homeTeam.name}</h6>
                </section>
                <section>
                  <h6>
                    {live.awayScore?.display} /{" "}
                    {live.awayScore?.innings?.inning1.wickets}{" "}
                    <span className="mx-2 text-secondary">
                      ({live.awayScore?.innings?.inning1.overs})
                    </span>
                  </h6>
                  <h6>
                    {live.homeScore?.display} /{" "}
                    {live.homeScore?.innings?.inning1.wickets}{" "}
                    <span className="mx-2 text-secondary">
                      ({live.homeScore?.innings?.inning1.overs})
                    </span>
                  </h6>
                </section>
              </div>
            </div>
          ))}
        </>
      )}

      {completedMatches.length > 0 && (
        <>
          <h6 className="mx-3">Results</h6>
          {completedMatches.map((completed, index) => (
            <button
              key={index}
              className="btn w-100 card-body text-white"
              onClick={() => handleClick(completed.id, completed)}
            >
              <section className="text-start mb-3 mx-3">
                <span className="fsize">{completed.tournament.name}</span>
                <br />
                <span className="fsize">
                  {convertTimestampToDate(completed.startTimestamp)} -{" "}
                  {completed.status.description}
                </span>
              </section>
              <div className="d-flex justify-content-between mx-4 mt-3">
                <section>
                  <h6>{completed.awayTeam.name}</h6>
                  <h6>{completed.homeTeam.name}</h6>
                </section>
                <section>
                  <h6>
                    {completed.awayScore?.innings?.inning1?.score} /{" "}
                    {completed.awayScore?.innings?.inning1.wickets}{" "}
                    <span className="mx-2 text-secondary">
                      ({completed.awayScore?.innings?.inning1.overs})
                    </span>
                  </h6>
                  <h6>
                    {completed.homeScore?.innings?.inning1?.score} /{" "}
                    {completed.homeScore?.innings?.inning1.wickets}{" "}
                    <span className="mx-2 text-secondary">
                      ({completed.homeScore?.innings?.inning1.overs})
                    </span>
                  </h6>
                </section>
              </div>
              <span className="fsize m-2">{completed.note}</span>
              <br />
            </button>
          ))}
        </>
      )}

      {/* No Matches Message */}
      {upcomingMatches.length === 0 &&
        liveMatches.length === 0 &&
        completedMatches.length === 0 && (
          <p className="text-secondary mx-3">No matches scheduled for today.</p>
        )}
    </div>
  );
};

export default Overview;

/////////////////////////////////////////////

// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";

// const Overview = () => {
//   const location = useLocation();
//   const { match, categoryId } = location.state || {};

//   console.log(categoryId, match);

//   const [matches, setMatches] = useState([]);
//   const navigate = useNavigate();
//   console.log(matches);

//   useEffect(() => {
//     if (!match || !categoryId) return;

//     const today = new Date();
//     const startOfDay = new Date(
//       today.getFullYear(),
//       today.getMonth(),
//       today.getDate()
//     ).getTime();
//     const endOfDay = new Date(
//       today.getFullYear(),
//       today.getMonth(),
//       today.getDate(),
//       23,
//       59,
//       59,
//       999
//     ).getTime();

//     const filteredMatches = match.filter(
//       (match) =>
//         match.tournament.category.id === categoryId &&
//         match.startTimestamp * 1000 >= startOfDay &&
//         match.startTimestamp * 1000 <= endOfDay
//     );
// console.log(filteredMatches);
//     setMatches(filteredMatches);
//   }, [match, categoryId]);

//   const handleClick = (clickedMatch, match) => {
//     navigate("/cricket/league/today", { state: { match, clickedMatch } });
//   };

//   const convertTimestampToDate = (timestamp) => {
//     const milliseconds = timestamp * 1000;
//     const dateObject = new Date(milliseconds);
//     const dateString = dateObject.toISOString().split("T")[0];
//     const timeString = dateObject.toTimeString().split(" ")[0];
//     return `${dateString} ${timeString}`;
//   };

//   const upcomingMatches = matches.filter((match) => match.status.code === 0);
//   const liveMatches = matches.filter((match) => match.status.code === 21);
//   const completedMatches = matches.filter((match) => match.status.code === 100);

//   return (
//     <div className="mt-4">
//       {/* Upcoming Matches */}
//       {upcomingMatches.length > 0 && (
//         <>
//           <h6 className="mx-3">Fixtures</h6>
//           {upcomingMatches.map((match, index) => (
//             <button
//               key={index}
//               className="btn w-100 card-body text-white my-3"
//               onClick={() => handleClick(match.id, match)}
//             >
//               <div className="mx-3 text-start">
//                 <span className="fsize">{match.tournament.name}</span>
//                 <br />
//                 <p className="fsize">
//                   {convertTimestampToDate(match.startTimestamp)} -{" "}
//                   {match.status.description}
//                 </p>
//                 <div className="d-flex justify-content-between mx-3 mt-3">
//                   <section>
//                     <h6>{match.awayTeam.name}</h6>
//                     <h6>{match.homeTeam.name}</h6>
//                   </section>
//                 </div>
//               </div>
//             </button>
//           ))}
//         </>
//       )}

//       {/* Live Matches */}
//       {liveMatches.length > 0 && (
//         <>
//           {liveMatches.map((live, index) => (
//             <div
//               key={index}
//               className="card-body text-white cursor"
//               onClick={() => handleClick(live.id)}
//             >
//               <span className="fsize m-2">{live?.tournament.name}</span>
//               <br />
//               <div className="d-flex justify-content-between m-2">
//                 <section>
//                   <h6>{live.awayTeam.name}</h6>
//                   <h6>{live.homeTeam.name}</h6>
//                 </section>
//                 <section>
//                   <h6>
//                     {live.awayScore?.display} /{" "}
//                     {live.awayScore?.innings?.inning1.wickets}{" "}
//                     <span className="mx-2 text-secondary">
//                       ({live.awayScore?.innings?.inning1.overs})
//                     </span>
//                   </h6>
//                   <h6>
//                     {live.homeScore?.display} /{" "}
//                     {live.homeScore?.innings?.inning1.wickets}{" "}
//                     <span className="mx-2 text-secondary">
//                       ({live.homeScore?.innings?.inning1.overs})
//                     </span>
//                   </h6>
//                 </section>
//               </div>
//             </div>
//           ))}
//         </>
//       )}

//       {completedMatches.length > 0 && (
//         <>
//           <h6 className="mx-3">Results</h6>
//           {completedMatches.map((completed, index) => (
//             <button
//               key={index}
//               className="btn w-100 card-body text-white"
//               onClick={() => handleClick(completed.id, completed)}
//             >
//               <section className="text-start mb-3 mx-3">
//                 <span className="fsize">{completed.tournament.name}</span>
//                 <br />
//                 <span className="fsize">
//                   {convertTimestampToDate(completed.startTimestamp)} -{" "}
//                   {completed.status.description}
//                 </span>
//               </section>
//               <div className="d-flex justify-content-between mx-4 mt-3">
//                 <section>
//                   <h6>{completed.awayTeam.name}</h6>
//                   <h6>{completed.homeTeam.name}</h6>
//                 </section>
//                 <section>
//                   <h6>
//                     {completed.awayScore?.innings?.inning1?.score} /{" "}
//                     {completed.awayScore?.innings?.inning1.wickets}{" "}
//                     <span className="mx-2 text-secondary">
//                       ({completed.awayScore?.innings?.inning1.overs})
//                     </span>
//                   </h6>
//                   <h6>
//                     {completed.homeScore?.innings?.inning1?.score} /{" "}
//                     {completed.homeScore?.innings?.inning1.wickets}{" "}
//                     <span className="mx-2 text-secondary">
//                       ({completed.homeScore?.innings?.inning1.overs})
//                     </span>
//                   </h6>
//                 </section>
//               </div>
//               <span className="fsize m-2">{completed.note}</span>
//               <br />
//             </button>
//           ))}
//         </>
//       )}

//       {/* No Matches Message */}
//       {upcomingMatches.length === 0 &&
//         liveMatches.length === 0 &&
//         completedMatches.length === 0 && (
//           <p className="text-secondary mx-3">No matches scheduled for today.</p>
//         )}
//     </div>
//   );
// };

// export default Overview;
