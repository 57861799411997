import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import HandballInfo from "../otherFiles/HandballInfo";
import HandballSummary from "../otherFiles/HandballSummary";
import PointTable from "../otherFiles/PointTable";
// import LineUps from "../otherFiles/LineUps";

const HandballTodayMatch = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("baseInfo") || "Info"
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    localStorage.setItem("baseInfo", activeTab);

    return () => {
      localStorage.removeItem("baseInfo");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const convertTimestampToTimeOnly = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const timeString = dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return timeString;
  };

  const DateOnly = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { month: "short", day: "2-digit" };
    const dateString = date.toLocaleDateString("en-US", options);
    return dateString;
  };

  const handleRoute = () => {
    navigate("/handball");
  };

  return (
    <div>
      <div className="d-flex">
        <button
          onClick={handleRoute}
          className="btn btn-secondary w-100 d-flex align-items-center gap-2 mx-2 p-1"
          style={{ backgroundColor: "#111" }}
        >
          <ReactCountryFlag
            countryCode={match?.tournament?.category?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.tournament?.category?.country?.alpha3}
          />
          <div className="mx-2 text-start">
            <p>{match?.tournament?.name}</p>
            <p>{match?.tournament?.category?.country?.name}</p>
          </div>
        </button>
      </div>
      <hr />
      <div className="bg-dark mx-2 d-flex justify-content-evenly align-items-center">
        <div className="text-center">
          <ReactCountryFlag
            countryCode={match?.homeTeam?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.homeTeam?.country?.alpha3}
          />
          <p className="p-2">{match?.homeTeam?.name}</p>
        </div>
        <div>
          <h6>{convertTimestampToTimeOnly(match?.startTimestamp)}</h6>
          <p className="fsize">{DateOnly(match?.startTimestamp)}</p>
        </div>
        <div className="text-center">
          <ReactCountryFlag
            countryCode={match?.awayTeam?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.awayTeam?.country?.alpha3}
          />
          <p className="p-2">{match?.awayTeam?.name}</p>
        </div>
      </div>
      <div className="d-flex gap-2 m-2">
        <Link
          to="/handball/league/today/info"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Info" ? "active" : ""
          }`}
          onClick={() => handleClick("Info")}
        >
          Info
        </Link>
        <Link
          to="/handball/league/today/summary"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Summary" ? "active" : ""
          }`}
          onClick={() => handleClick("Summary")}
        >
          Summary
        </Link>
        <Link
          to="/handball/league/today/table"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Table" ? "active" : ""
          }`}
          onClick={() => handleClick("Table")}
        >
          Table
        </Link>
        {/* <Link
          to="/handball/league/today/players"
          state={{ match }}
          className={`link-eff btn btn-sm p-1 ${
            activeTab === "Team" ? "active" : ""
          }`}
          onClick={() => handleClick("Team")}
        >
          Line-Ups
        </Link> */}
      </div>
      {activeTab === "Info" && <HandballInfo />}
      {activeTab === "Summary" && <HandballSummary />}
      {activeTab === "Table" && <PointTable />}
      {/* {activeTab === "Team" && <LineUps />} */}
    </div>
  );
};

export default HandballTodayMatch;
