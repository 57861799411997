import React from "react";
import Cricket from "./Cricket";
import { Route, Routes } from "react-router-dom";
import MatchHome from "./Matches/MatchHome";
import { TodaysMatch } from "./Matches/TodaysMatch";
import { FutureMatches } from "./Matches/FutureMatches";
import LeagueComponent from "./LeagueDetail/LeagueComponent";
import ScoreCard from "./ScoreCard";
import Info from "./Info";
import PointTable from "./PointTable";
import Live from "./Live/Live";
import Favourites from "../Favourites/Favourites";
import Overview from "./Matches/Overview";
import { OlderMatches } from "./Matches/OlderMatches";
import Result from "./Result/Result";
import Players from "./Players";
import Error from "../Error";

const CricketIndex = () => {
  return (
    <div>
        <Routes>
          <Route path="/" element={<Cricket />} />
          <Route path={`/:dated`} element={<Cricket />} />
          <Route path="/matches" element={<MatchHome />} />
          <Route path="/league/today/*" element={<TodaysMatch />} />
          <Route path="/future" element={<FutureMatches />} />
          <Route path="/scorecard" element={<ScoreCard />} />
          <Route path="/info" element={<Info />} />
          <Route path="/table" element={<PointTable />} />
          <Route path="/players" element={<Players />} />
          <Route path="/live" element={<Live />} />
          <Route path="/league/result/*" element={<Result />} />
          <Route path="/fav" element={<Favourites />} />
          <Route path="/league/*" element={<LeagueComponent />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/last_matches" element={<OlderMatches />} />
          {/* <Route path="*" element={<Error />} /> */}
        </Routes>
    </div>
  );
};

export default CricketIndex;
