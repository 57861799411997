import React from "react";
import { Route, Routes } from "react-router-dom";
import HandballHome from "./HandballHome";
import HandballLeague from "./Leagues/HandballLeague";
import HandballMatches from "./Matches/HandballMatches";
import HandballFutureMatch from "./Matches/HandballFutureMatch";
import HandballResult from "./otherFiles/HandballResult";
import HandballTodayMatch from "./Matches/HandballTodayMatch";
import HandballOlderMatch from "./Matches/HandballOlderMatch";
import HandballSummary from "./otherFiles/HandballSummary";
import HandballOverview from "./Matches/HandballOverview";
import HandballLive from "./LiveScores/HandballLive";
import PointTable from "./otherFiles/PointTable";
import LineUps from "./otherFiles/LineUps";
import Error from "../Error";

const HandballIndex = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HandballHome />} />
        <Route path={`/:dated`} element={<HandballHome />} />
        <Route path="/league/*" element={<HandballLeague />} />
        <Route path="/match/*" element={<HandballMatches />} />
        <Route path="/live" element={<HandballLive />} />
        <Route path="/future" element={<HandballFutureMatch />} />
        <Route path="/past_match" element={<HandballOlderMatch />} />
        <Route path="/league/result/*" element={<HandballResult />} />
        <Route path="/league/today/*" element={<HandballTodayMatch />} />
        <Route path="/summary" element={<HandballSummary />} />
        <Route path="/table" element={<PointTable />} />
        <Route path="/players" element={<LineUps />} />
        <Route path="/overview" element={<HandballOverview />} />
        {/* <Route path="*" element={<Error />} /> */}
      </Routes>
    </div>
  );
};

export default HandballIndex;
