import React from "react";
import { Route, Routes } from "react-router-dom";
import FootInfo from "./FootInfo";
import FootResult from "./FootResult";
import LeagueData from "./LeagueData/LeagueData";
import FootScoreCard from "./LiveScore/FootScoreCard";
import FootLiveScore from "./LiveScore/FootLiveScore";
import FootMatchesHome from "./Matches/FootMatchesHome";
import FootTodaysMatch from "./Matches/FootTodaysMatch";
import Football from "./Football";
import FootOverview from "./Matches/FootOverview";
import Table from "./Table";
import FootFutureMatch from "./Matches/FootFutureMatch";
import LineUps from "./LineUps";
import Error from "../Error";

const FootballIndex = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Football />} />
        <Route path={`/:dated`} element={<Football />} />
        <Route path="/info" element={<FootInfo />} />
        <Route path="/league/result/*" element={<FootResult />} />
        <Route path="/league/*" element={<LeagueData />} />
        <Route path="/score" element={<FootScoreCard />} />
        <Route path="/live" element={<FootLiveScore />} />
        <Route path="/match" element={<FootMatchesHome />} />
        <Route path="/league/todaymatch/*" element={<FootTodaysMatch />} />
        <Route path="/overview" element={<FootOverview />} />
        <Route path="/table" element={<Table />} />
        <Route path="/line_ups" element={<LineUps />} />
        <Route path="/future_matches" element={<FootFutureMatch />} />
        {/* <Route path="*" element={<Error/>} /> */}
      </Routes>
    </div>
  );
};

export default FootballIndex;
