import React, { useEffect, useState } from "react";
import FootInfo from "./FootInfo";
import FootScoreCard from "./LiveScore/FootScoreCard";
import Table from "./Table";
import { Link, useLocation, useNavigate } from "react-router-dom";
import countryFlag from "../../assets/football.png";
import ReactCountryFlag from "react-country-flag";
import LineUps from "./LineUps";

const FootResult = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("matchHomeActiveTab") || "footscore"
  );
  const navigate = useNavigate();

  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    localStorage.setItem("matchHomeActiveTab", activeTab);

    return () => {
      localStorage.removeItem("matchHomeActiveTab");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const handleRoute = () => {
    navigate("/football");
  };

  return (
    <div className="p-3">
      <div className="d-flex">
        <button
          onClick={handleRoute}
          className="btn btn-secondary w-100 d-flex align-items-center gap-2 mx-2 p-1"
          style={{ backgroundColor: "#111" }}
        >
          <ReactCountryFlag
            countryCode={match?.tournament?.category?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.tournament?.category?.country?.alpha3}
          />
          <div className="mx-2 text-start">
            <p>{match?.tournament?.name}</p>
            <p>{match?.tournament?.category?.country?.name}</p>
          </div>
        </button>
      </div>
      <hr />
      <div className="bg-dark mx-2 p-3 d-flex justify-content-evenly">
        <div className="text-center">
          <ReactCountryFlag
            countryCode={match?.homeTeam?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.homeTeam?.country?.alpha3}
          />
          <p className="p-2">
            {/* <img src={countryFlag} alt="flag" style={{width:'20px'}} /> */}
            {match?.homeTeam?.name}
          </p>
        </div>
        <div className="d-flex gap-1 align-items-center">
          {/* {match ? <p className="fsize">{convertTimestampToTimeOnly(match?.startTimestamp)}</p> : <p className="fsize">FT</p> } */}
          <p>{match?.homeScore?.display}</p>-<p>{match?.awayScore?.display}</p>
        </div>
        <div className="text-center">
          <ReactCountryFlag
            countryCode={match?.awayTeam?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.awayTeam?.country?.alpha3}
          />
          <p className="p-2">
            {/* <img src={countryFlag} alt="flag" style={{width:'20px'}} /> */}
            {match?.awayTeam?.name}
          </p>
        </div>
      </div>
      <div>
        <div className="my-2 d-flex gap-2 mx-2">
          <Link
            to="/football/league/result/info"
            state={{ match }}
            className={`link-eff btn btn-sm p-2 ${
              activeTab === "Info" ? "active" : ""
            }`}
            onClick={() => handleClick("Info")}
          >
            Info
          </Link>
          <Link
            to="/football/league/result/score"
            state={{ match }}
            className={`link-eff btn btn-sm p-2 ${
              activeTab === "footscore" ? "active" : ""
            }`}
            onClick={() => handleClick("footscore")}
          >
            Summary
          </Link>
          <Link
            to="/football/league/result/line-ups"
            state={{ match }}
            className={`link-eff btn btn-sm p-2 ${
              activeTab === "LineUps" ? "active" : ""
            }`}
            onClick={() => handleClick("LineUps")}
          >
            Line-Ups
          </Link>
          <Link
            to="/football/league/result/table"
            state={{ match }}
            className={`link-eff btn btn-sm p-2 ${
              activeTab === "Table" ? "active" : ""
            }`}
            onClick={() => handleClick("Table")}
          >
            Table
          </Link>
        </div>
        <hr />
        {activeTab === "Info" && <FootInfo />}
        {activeTab === "footscore" && <FootScoreCard />}
        {activeTab === "Table" && <Table />}
        {activeTab === "LineUps" && <LineUps />}
      </div>
    </div>
  );
};

export default FootResult;
