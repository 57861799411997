import React from 'react'
import { useLocation } from 'react-router-dom';

const BaseInfo = () => {
  const location = useLocation();
  const { match } = location.state || {};

  const convertTimestampToDate = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const dateString = dateObject.toISOString().split("T")[0];
    const timeString = dateObject.toTimeString().split(" ")[0];
    return `${dateString} ${timeString}`;
  };
  
  return (
    <div className='px-3'>
      <p className='fsize m-3'>Match - {convertTimestampToDate(match?.startTimestamp)}</p>
    </div>
  )
}

export default BaseInfo