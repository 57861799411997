import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HandballOverview from "../Matches/HandballOverview";
import HandballMatches from "../Matches/HandballMatches";

const HandballLeague = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("handLeague") || "Overview"
  );
  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    localStorage.setItem("baseLeague", activeTab);

    return () => {
      localStorage.removeItem("baseLeague");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="card-title d-flex gap-2 px-2">
        <Link
          to="/handball/league/overview"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Overview" ? "active" : ""
          }`}
          onClick={() => handleClick("Overview")}
        >
          Overview
        </Link>
        <Link
          to="/handball/league/match"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "handMatch" ? "active" : ""
          }`}
          onClick={() => handleClick("handMatch")}
        >
          Matches
        </Link>
      </div>
      {activeTab === "Overview" && <HandballOverview />}
      {activeTab === "handMatch" && <HandballMatches />}
      <br />
    </div>
  );
};

export default HandballLeague;
