import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VolleySummary from "./VolleySummary";
import VolleyInfo from "./VolleyInfo";
import ReactCountryFlag from "react-country-flag";
import PointTable from "./PointTable";

const VolleyResult = () => {
  const [activeTab, setActiveTab] = useState("Info");
  const navigate = useNavigate();
  const location = useLocation();
  const { match } = location.state || {};

  const handleClick = (tab) => {
    setActiveTab(activeTab === tab ? null : tab);
  };

  const handleRoute = () => {
    navigate("/volleyball");
  };

  const convertTimestampToTimeOnly = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const timeString = dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return timeString;
  };

  return (
    <div>
      <div className="d-flex">
        <button
          onClick={handleRoute}
          className="btn btn-secondary w-100 d-flex align-items-center gap-2 mx-2 p-1"
          style={{ backgroundColor: "#111" }}
        >
          <ReactCountryFlag
            countryCode={match?.tournament?.uniqueTournament?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.tournament?.uniqueTournament?.country?.alpha2}
          />
          <div className="mx-2 text-start">
            <div>{match?.tournament?.uniqueTournament?.name}</div>
            <div>
              {match?.tournament?.uniqueTournament?.category?.country?.name}
            </div>
          </div>
        </button>
      </div>
      <hr />
      <div className="bg-dark mx-2 d-flex justify-content-evenly align-items-center">
        <button className="btn p-2">
          <ReactCountryFlag
            countryCode={match?.homeTeam?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.homeTeam?.country?.alpha2}
          />
          <p>{match?.homeTeam?.name}</p>
        </button>
        <div className="d-flex gap-1 align-items-center">
          {/* {match ? <p className="fsize">{convertTimestampToTimeOnly(match?.startTimestamp)}</p> : <p className="fsize">FT</p> } */}
          <p>{match?.homeScore?.display}</p>-<p>{match?.awayScore?.display}</p>
        </div>
        <button className="btn p-2">
          <ReactCountryFlag
            countryCode={match?.awayTeam?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.awayTeam?.country?.alpha2}
          />
          <p>{match?.awayTeam?.name}</p>
        </button>
      </div>
      <hr />
      <div className="d-flex gap-2 my-2">
        <button
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Info" ? "active" : ""
          }`}
          state={{ match }}
          onClick={() => handleClick("Info")}
        >
          Info
        </button>
        <button
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Summary" ? "active" : ""
          }`}
          state={{ match }}
          onClick={() => handleClick("Summary")}
        >
          Summary
        </button>
        <button
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Table" ? "active" : ""
          }`}
          state={{ match }}
          onClick={() => handleClick("Table")}
        >
          PointTable
        </button>
      </div>
      {activeTab === "Info" && <VolleyInfo />}
      {activeTab === "Summary" && <VolleySummary />}
      {activeTab === "Table" && <PointTable />}
    </div>
  );
};

export default VolleyResult;
