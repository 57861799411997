import React, { useEffect, useState } from "react";
import HockFutureMatch from "./HockFutureMatch";
import HockOlderMatch from "./HockOlderMatch";
import { Link, useLocation } from "react-router-dom";

const HockMatches = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("matchFuture") || "Futures"
  );
  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    localStorage.setItem("matchFuture", activeTab);

    return () => {
      localStorage.removeItem("matchFuture");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="mx-4">
      <div className="p-3 d-flex gap-2">
        <Link
          to="/hockey/leagues/future_match"
          state={{ match }}
          className={`btn btn-sm p-2 ${
            activeTab === "Futures" ? "active" : ""
          }`}
          onClick={() => handleClick("Futures")}
        >
          Futures
        </Link>
        <Link
          to="/hockey/leagues/past_match"
          state={{ match }}
          className={`btn btn-sm p-2 ${
            activeTab === "Results" ? "active" : ""
          }`}
          onClick={() => handleClick("Results")}
        >
          Results
        </Link>
      </div>
      {activeTab === "Futures" && <HockFutureMatch />}
      {activeTab === "Results" && <HockOlderMatch />}
    </div>
  );
};

export default HockMatches;
