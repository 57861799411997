import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { KeyProvider } from "../../KeyProvider";

const Info = () => {
  const [resData, setResData] = useState([]);
  const date = new Date().toISOString().split("T")[0];

  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    const getSportsData = async () => {
      const defaultUrl =
        "https://os-sports-perform.p.rapidapi.com/v1/events/data";
      const defaultParams = {
        event_id: match.id,
      };

      const options = {
        headers: {
          "X-RapidAPI-Key": KeyProvider ,
          "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.get(defaultUrl, {
          params: defaultParams,
          headers: options.headers,
        });

        setResData(response.data.data);;
        
      } catch (error) {
        console.error("Error fetching Scorecard data:", error);
      }
    };

    getSportsData();
  }, [date]);


 const convertTimestampToDate = (timestamp) => {
  const milliseconds = timestamp * 1000;
  const dateObject = new Date(milliseconds);
  const dateString = dateObject.toISOString().split("T")[0];
  const timeString = dateObject.toTimeString().split(" ")[0];
  return `${dateString} ${timeString}`;
};

  return (
    <div className="mt-3">
      <strong className="mx-2">Match Info</strong>
      {resData ? (
        <div className="d-flex gap-5 mx-4">
          <div className="text-secondary">
            <p>Event</p>
            <p>Starts</p>
            <p>Venue</p>
            <p>City</p>
            <p>Country</p>
          </div>
          <div>
            <p>{resData.tournament?.name}</p>
            <p>{resData.startTimestamp ? convertTimestampToDate(resData.startTimestamp): 'N/A'}</p>
            <p>{resData.venue?.stadium?.name || 'N/A'}</p>
            <p>{resData.venue?.city?.name || 'N/A'}</p>
            <p>{resData.venue?.country?.name || 'N/A'}</p>
          </div>
        </div>
      ) : (
        <p className="text-secondary mx-3">No match data available.</p>
      )}
    </div>
  );
};

export default Info;
