import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ScoreCard from "../ScoreCard";
import Players from "../Players";
import PointTable from "../PointTable";
import Info from "../Info";

const Result = () => {
  const location = useLocation();
  const { match } = location.state || {};
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("resultTab") || "Info"
  );
  const navigate = useNavigate();

  const convertTimestampToDate = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const dateString = dateObject.toISOString().split("T")[0];
    const timeString = dateObject.toTimeString().split(" ")[0];
    return `${dateString} ${timeString}`;
  };

  useEffect(() => {
    localStorage.setItem("resultTab", activeTab);
    return () => {
      localStorage.removeItem("resultTab");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const handleRoute = (match) => {
    navigate("/cricket", { state: { match } });
  };

  return (
      <div className="mx-2">
        <div className="d-flex">
          <button
            onClick={() => handleRoute(match)}
            className="btn w-100 d-flex align-items-center gap-2 mx-2 p-1"
            style={{ backgroundColor: "#111" }}
          >
            <div className="mx-2 text-start">
              <div>{match.tournament.name}</div>
              <div>{match.tournament.category.country.name}</div>
            </div>
          </button>
        </div>
        <hr />
        <div className="m-2">
          <div className="text-white w-100">
            <div>
              <section>
                <span className="fsize">{match.tournament.name}</span>
                <br />
                <span className="fsize">
                  {convertTimestampToDate(match.startTimestamp)} -{" "}
                  {match.status.description}
                </span>
              </section>
              <section className="d-flex justify-content-between mt-2">
                <div>
                  <h6>{match.homeTeam.name}</h6>
                  <h6>{match.awayTeam.name}</h6>
                </div>
                <section>
                  <h6>
                    {match.homeScore?.display}/
                    {match.homeScore?.innings?.inning1.wickets}{" "}
                    <span className="text-secondary">
                      ({match.homeScore?.innings?.inning1.overs})
                    </span>
                  </h6>
                  <h6>
                    {match.awayScore?.display}/
                    {match.awayScore?.innings?.inning1.wickets}{" "}
                    <span className="text-secondary">
                      ({match.awayScore?.innings?.inning1.overs})
                    </span>
                  </h6>
                </section>
              </section>
            </div>
            <p className="text-center mb-0 p-0">{match.note}</p>
          </div>
        </div>
        <div>
          <div className="my-3 d-flex gap-2">
            <Link
              to="/cricket/league/result/info"
              state={{ match }}
              className={`link-eff btn btn-sm p-2 ${
                activeTab === "Info" ? "active" : ""
              }`}
              onClick={() => handleClick("Info")}
            >
              Info
            </Link>
            <Link
              to="/cricket/league/result/scorecard"
              state={{ match }}
              className={`link-eff btn btn-sm p-2 ${
                activeTab === "Scorecard" ? "active" : ""
              }`}
              onClick={() => handleClick("Scorecard")}
            >
              Scorecard
            </Link>
            <Link
              to="/cricket/league/result/players"
              state={{ match }}
              className={`link-eff btn btn-sm p-2 ${
                activeTab === "Teams" ? "active" : ""
              }`}
              onClick={() => handleClick("Teams")}
            >
              Teams
            </Link>
            <Link
              to="/cricket/league/result/table"
              state={{ match }}
              className={`link-eff btn btn-sm p-2 ${
                activeTab === "Table" ? "active" : ""
              }`}
              onClick={() => handleClick("Table")}
            >
              Table
            </Link>
          </div>
          {activeTab === "Info" && <Info />}
          {activeTab === "Scorecard" && <ScoreCard />}
          {activeTab === "Teams" && <Players />}
          {activeTab === "Table" && <PointTable />}
        </div>
      </div>
  );
};

export default Result;
