import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const OlderMatches = () => {
  const location = useLocation();
  const { match } = location.state || {};
  const navigate = useNavigate();

  const handleClick = (match) => {
    navigate("/cricket/league/result", { state: { match } });
  };

  const convertTimestampToDate = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    return dateObject.toLocaleString();
  };

  return (
    <div>
      <div className="fs-5 text-secondary">Results</div>
      {match
        ?.filter((match) => match.status.code === 100)
        .map((match, index) => (
          <button
            key={index}
            className="card-body btn btn-sm w-100 mb-2 text-start text-white"
            onClick={() => handleClick(match)}
          >
            <span className="fsize">{match.tournament.name}</span>
            <br />
            <p className="fsize">
              {convertTimestampToDate(match.startTimestamp)} -{" "}
              {match.status.description}
            </p>
            <div className="d-flex justify-content-between">
              <section>
                <h6>{match.awayTeam.name}</h6>
                <h6>{match.homeTeam.name}</h6>
              </section>
              <section>
                <h6>
                  {match.homeScore?.display}/
                  {match.homeScore?.innings?.inning1.wickets}{" "}
                  <span className="text-secondary">
                    ({match.homeScore?.innings?.inning1.overs})
                  </span>
                </h6>
                <h6>
                  {match.awayScore?.display}/
                  {match.awayScore?.innings?.inning1.wickets}{" "}
                  <span className="text-secondary">
                    ({match.awayScore?.innings?.inning1.overs})
                  </span>
                </h6>
              </section>
            </div>
            <h6 className="fsize">{match.note}</h6>
          </button>
        ))}
    </div>
  );
};
