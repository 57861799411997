import React from "react";
import ReactCountryFlag from "react-country-flag";
import { useLocation, useNavigate } from "react-router-dom";

const BaseOlderMatch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { match } = location.state || {};

  const handleClick = (match) => {
    navigate("/baseball/league/result", { state: { match } });
  };

  const resultedMatch = match.filter((match) => match.status.code === 100);

  return (
    <div className="p-3">
      {resultedMatch && resultedMatch.length > 0 ? (
        resultedMatch.map((match, index) => (
          <button
            key={index}
            className="card-body btn w-100 text-white d-flex justify-content-between align-items-center mb-2 p-3"
            onClick={() => handleClick(match)}
          >
            <div className="d-flex align-items-center">
              <p className="fsize">FT</p>
              <section className="mx-4">
                <div className="d-flex gap-2">
                  <ReactCountryFlag
                    countryCode={match?.homeTeam?.country?.alpha2}
                    svg
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                    title={match?.homeTeam?.country?.alpha3}
                  />
                  <h6>{match?.homeTeam?.name}</h6>
                </div>
                <div className="d-flex gap-2">
                  <ReactCountryFlag
                    countryCode={match?.awayTeam?.country?.alpha2}
                    svg
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                    title={match?.awayTeam?.country?.alpha3}
                  />
                  <h6>{match?.awayTeam?.name}</h6>
                </div>
              </section>
            </div>
            <div>
              <p>{match?.homeScore?.display}</p>
              <p>{match?.awayScore?.display}</p>
            </div>
          </button>
        ))
      ) : (
        <p>There is No Matches Today</p>
      )}
    </div>
  );
};

export default BaseOlderMatch;
