// import axios from "axios";
// import { createContext, useContext, useEffect, useState } from "react";
// import debounce from "lodash/debounce";
// import { KeyProvider } from "../../KeyProvider";

// const HandballContext = createContext();

// export const useHandball = () => useContext(HandballContext);

// export const HandballProvider = ({ children, selectedDate }) => {
//   const [resData, setResData] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const fetchCategoryIds = async () => {
//     try {
//       const response = await axios.get("https://os-sports-perform.p.rapidapi.com/v1/categories?sport_id=6", {
//         headers: {
//           "X-RapidAPI-Key": KeyProvider,
//           "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
//         },
//       });
//       const fetchedCategories = response?.data?.data || [];
//       setCategories(fetchedCategories);
//     } catch (error) {
//       console.error("Error fetching category IDs:", error);
//     }
//   };

//   const fetchSportsDataForBatch = async (date, batch) => {
//     const data = await Promise.all(batch.map(async (category) => {
//       const defaultUrl = "https://os-sports-perform.p.rapidapi.com/v1/events/schedule/category";
//       const defaultParams = {
//         date: date.toISOString().split('T')[0],
//         sport_id: "6",
//         category_id: category.id,
//       };

//       const options = {
//         headers: {
//           "X-RapidAPI-Key": KeyProvider,
//           "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
//         },
//         params: defaultParams,
//       };

//       try {
//         const response = await axios.get(defaultUrl, options);
//         return response?.data?.data || [];
//       } catch (error) {
//         console.error(`Error fetching data for category ${category.id}:`, error);
//         return [];
//       }
//     }));
//     return data.flat();
//   };

//   const fetchAllSportsData = async (date, categoryIds) => {
//     setIsLoading(true);
//     const batchSize = 4;
//     let combinedData = [];

//     for (let i = 0; i < categoryIds.length; i += batchSize) {
//       const batch = categoryIds.slice(i, i + batchSize);
//       const batchData = await fetchSportsDataForBatch(date, batch);
//       combinedData = [...combinedData, ...batchData];

//       if (i + batchSize < categoryIds.length) {
//         await new Promise((resolve) => setTimeout(resolve, 1000));
//       }
//     }

//     setResData(combinedData);
//     setIsLoading(false);
//     console.log("Handball Date Data:", combinedData);
//   };

//   const debouncedFetchAllSportsData = debounce(fetchAllSportsData, 500);

//   useEffect(() => {
//     fetchCategoryIds();
//   }, []);

//   useEffect(() => {
//     if (categories.length) {
//       debouncedFetchAllSportsData(selectedDate, categories);
//     }
//     return () => {
//       debouncedFetchAllSportsData.cancel();
//     };
//   }, [selectedDate, categories]);

//   return (
//     <HandballContext.Provider value={{ selectedDate, resData, isLoading, categories }}>
//       {children}
//     </HandballContext.Provider>
//   );
// };


////////////////////////////////////////////////////////////////////////////

import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { KeyProvider } from "../../KeyProvider";

const HandballContext = createContext();

export const useHandball = () => useContext(HandballContext);

export const HandballProvider = ({ children, selectedDate }) => {
  const [resData, setResData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Function to fetch category IDs
  const fetchCategoryIds = async () => {
    try {
      const response = await axios.get(
        "https://os-sports-perform.p.rapidapi.com/v1/categories?sport_id=6",
        {
          headers: {
            "X-RapidAPI-Key": KeyProvider,
            "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
          },
        }
      );
      const fetchedCategories = response?.data?.data || [];
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Error fetching category IDs:", error);
    }
  };


  // Function to fetch sports data for a batch of categories
  const fetchSportsDataForBatch = async (date, batch) => {
    const defaultUrl =
      "https://os-sports-perform.p.rapidapi.com/v1/events/schedule/category";
    const batchSize = 4;

    const requests = batch.map(async (category) => {
      const defaultParams = {
        date: date.toISOString().split("T")[0],
        sport_id: "6",
        category_id: category.id,
      };

      const options = {
        headers: {
          "X-RapidAPI-Key": KeyProvider,
          "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
        },
        params: defaultParams,
      };

      try {
        const response = await axios.get(defaultUrl, options);
        return response?.data?.data || [];
      } catch (error) {
        console.error(`Error fetching data for category ${category.id}:`, error);
        return [];
      }
    });

    const responseData = await Promise.all(requests);
    return responseData.flat();
  };

  // Function to fetch all sports data for all categories
  const fetchAllSportsData = async (date, categoryIds) => {
    setIsLoading(true);
    const batchSize = 4;
    let combinedData = [];

    for (let i = 0; i < categoryIds.length; i += batchSize) {
      const batch = categoryIds.slice(i, i + batchSize);
      const batchData = await fetchSportsDataForBatch(date, batch);
      combinedData = [...combinedData, ...batchData];

      if (i + batchSize < categoryIds.length) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }

    setResData(combinedData);
    setIsLoading(false);
  };

  // Debounced version of fetchAllSportsData to limit API calls
  const debouncedFetchAllSportsData = debounce(fetchAllSportsData, 500);

  // Fetch category IDs on component mount
  useEffect(() => {
    fetchCategoryIds();
  }, []);

  // Fetch data when selectedDate or categories change
  useEffect(() => {
    if (categories.length) {
      debouncedFetchAllSportsData(selectedDate, categories);
    }
    return () => {
      debouncedFetchAllSportsData.cancel();
    };
  }, [selectedDate, categories]);

  return (
    <HandballContext.Provider
      value={{ selectedDate, resData, isLoading, categories }}
    >
      {children}
    </HandballContext.Provider>
  );
};
