import React, { useState } from "react";
import FootFutureMatch from "./FootFutureMatch";
import FootOlderMatch from "./FootOlderMatch";
import { Link, useLocation } from "react-router-dom";

const FootMatchesHome = () => {
  const [activeTab, setActiveTab] = useState("Futures");
  const location = useLocation();
  const { match } = location.state || {};

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="w-100 mx-4 p-3">
        <div className="my-2 d-flex gap-2">
          <Link
            to="/football/league/future_matches"
            state={{ match }}
            className={`btn btn-sm p-2 ${
              activeTab === "Futures" ? "active" : ""
            }`}
            onClick={() => handleClick("Futures")}
          >
            Futures
          </Link>
          <Link
            to="/football/league/match/result"
            state={{ match }}
            className={`btn btn-sm p-2 ${
              activeTab === "Results" ? "active" : ""
            }`}
            onClick={() => handleClick("Results")}
          >
            Results
          </Link>
        </div>
        {activeTab === "Futures" && <FootFutureMatch />}
        {activeTab === "Results" && <FootOlderMatch />}
      </div>
    </div>
  );
};

export default FootMatchesHome;
