// import React from "react";
// import { useNavigate } from "react-router-dom";
// import countryFlag from "../../assets/cricket.png";
// import { useCricket } from "./CricketContext";
// import loader from "../../assets/Loader.gif";
// import ReactCountryFlag from "react-country-flag";

// const Cricket = () => {
//   const navigate = useNavigate();
//   const { selectedDate, resData, isLoading } = useCricket();

//   const handleClick = (game) => {
//     const match = game;
//     console.log(match);

//     if (match.status.code === 0) {
//       navigate("/cricket/league/today", { state: { match } });
//     } else if (match.status.code === 21 || match.status.code === 100) {
//       navigate("/cricket/league/result", { state: { match } });
//     }
//   };

//   const handleRoute = (games) => {
//     const match = games
//     console.log(match);
//     navigate("/cricket/league", { state: { match } });
//   };

//   const convertTimestampToDate = (timestamp) => {
//     const milliseconds = timestamp * 1000;
//     const dateObject = new Date(milliseconds);
//     const dateString = dateObject.toISOString().split("T")[0];
//     const timeString = dateObject.toTimeString().split(" ")[0];
//     return `${dateString} ${timeString}`;
//   };

//   const convertTimestampToDateOnly = (timestamp) => {
//     const milliseconds = timestamp * 1000;
//     const dateObject = new Date(milliseconds);
//     const dateString = dateObject.toISOString().split("T")[0];
//     return dateString;
//   };

//   const gamesByTournament = {};

//   resData.forEach((game) => {
//     const tournamentId = game.tournament?.uniqueTournament?.id;
//     if (tournamentId) {
//       if (!gamesByTournament[tournamentId]) {
//         gamesByTournament[tournamentId] = [];
//       }
//       gamesByTournament[tournamentId].push(game);
//     }
//   });

//   return (
//     <div className="my-2">
//       {isLoading ? (
//         <img src={loader} alt="loader" className="img-fluid" />
//       ) : (
//         Object.entries(gamesByTournament).map(([tournamentId, games]) => {
//           // Filter out games with status code 21
//           const filteredGames = games.filter((game) => game.status.code !== 21);

//           const tournamentGamesForSelectedDate = filteredGames.filter(
//             (game) =>
//               convertTimestampToDateOnly(game.startTimestamp) ===
//               selectedDate.toLocaleDateString('en-CA')
//           );

//           if (tournamentGamesForSelectedDate.length === 0) return null;

//           const tournamentData = {
//             name: tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
//               ?.name,
//             country:
//               tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
//                 ?.category?.country?.name,
//             flag: tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
//               ?.category?.country?.alpha2,
//             games: tournamentGamesForSelectedDate,
//           };

//           return (
//             <div key={tournamentId} className="px-4">
//               <div className="d-flex">
//                 <button
//                   onClick={() => handleRoute(games)}
//                   className="btn btn-secondary w-100 d-flex align-items-center gap-2 mx-2 p-1"
//                   style={{ backgroundColor: "#111" }}
//                 >
//                   {tournamentData.flag ? (
//                     <ReactCountryFlag
//                       countryCode={tournamentData.flag}
//                       svg
//                       style={{
//                         width: "1.5em",
//                         height: "1.5em",
//                       }}
//                       title={tournamentData.flag}
//                     />
//                   ) : (
//                     <img
//                       src={countryFlag}
//                       alt="flag"
//                       style={{ width: "1.5em", height: "1.5em" }}
//                     />
//                   )}

//                   <div className="mx-2 text-start">
//                     <div>{tournamentData.name}</div>
//                     <div>{tournamentData.country}</div>
//                   </div>
//                 </button>
//               </div>
//               {tournamentGamesForSelectedDate.map((game, index) => (
//                 <button
//                   key={index}
//                   className="card-body btn w-100 text-start text-white p-3 my-2"
//                   onClick={() => handleClick(game)}
//                 >
//                   <span className="fsize">{game?.tournament.name}</span>
//                   <br />
//                   <p className="fsize">
//                     {convertTimestampToDate(game.startTimestamp)} -{" "}
//                     {game.status.description}
//                   </p>
//                   <div className="d-flex justify-content-between mt-3">
//                     <section>
//                       <h6>{game.homeTeam.name}</h6>
//                       <h6>{game.awayTeam.name}</h6>
//                     </section>
//                     {game.status.code === 100 && (
//                       <section>
//                         {
//                           game.homeScore?.innings?.inning2 ? 
//                           <h6>
//                             {game.homeScore?.display} & {game.homeScore?.innings?.inning2?.score}/
//                             {game.homeScore?.innings?.inning2?.wickets}{" "}
//                             <span className="text-secondary">
//                               ({game.homeScore?.innings?.inning2?.overs})
//                             </span>
//                           </h6> :
//                           <h6>
//                             {game.homeScore?.display}/
//                             {game.homeScore?.innings?.inning1?.wickets}{" "}
//                             <span className="text-secondary">
//                               ({game.homeScore?.innings?.inning1?.overs})
//                             </span>
//                           </h6>
//                         }
//                         {
//                           game.awayScore?.innings?.inning2 ? 
//                           <h6>
//                             {game.awayScore?.display} & {game.awayScore?.innings?.inning2?.score}/
//                             {game.awayScore?.innings?.inning1?.wickets}{" "}
//                             <span className="text-secondary">
//                               ({game.awayScore?.innings?.inning1?.overs})
//                             </span>
//                           </h6>:
//                           <h6>
//                             {game.awayScore?.display}/
//                             {game.awayScore?.innings?.inning1?.wickets}{" "}
//                             <span className="text-secondary">
//                               ({game.awayScore?.innings?.inning1?.overs})
//                             </span>
//                           </h6>
//                         }
//                       </section>
//                     )}
//                     {game.status.code === 140 && (
//                       <section>
//                         {
//                           game.homeScore?.innings?.inning2 ? 
//                           <h6>
//                             {game.homeScore?.display} & {game.homeScore?.innings?.inning2?.score}/
//                             {game.homeScore?.innings?.inning2?.wickets}{" "}
//                             <span className="text-secondary">
//                               ({game.homeScore?.innings?.inning2?.overs})
//                             </span>
//                           </h6> :
//                           <h6>
//                             {game.homeScore?.display}/
//                             {game.homeScore?.innings?.inning1?.wickets}{" "}
//                             <span className="text-secondary">
//                               ({game.homeScore?.innings?.inning1?.overs})
//                             </span>
//                           </h6>
//                         }
//                         {
//                           game.awayScore?.innings?.inning2 ? 
//                           <h6>
//                             {game.awayScore?.display} & {game.awayScore?.innings?.inning2?.score}/
//                             {game.awayScore?.innings?.inning1?.wickets}{" "}
//                             <span className="text-secondary">
//                               ({game.awayScore?.innings?.inning1?.overs})
//                             </span>
//                           </h6>:
//                           <h6>
//                             {game.awayScore?.display}/
//                             {game.awayScore?.innings?.inning1?.wickets}{" "}
//                             <span className="text-secondary">
//                               ({game.awayScore?.innings?.inning1?.overs})
//                             </span>
//                           </h6>
//                         }
//                       </section>
//                     )}
//                   </div>
//                   {game.status.code === 100 && (
//                     <h6 className="fsize text-center">{game.note}</h6>
//                   )}
//                 </button>
//               ))}
//             </div>
//           );
//         })
//       )}
//     </div>
//   );
// };

// export default Cricket;

//////////////////////////////////////////////////////////////////////////////////////

import React from "react";
import { useNavigate } from "react-router-dom";
import countryFlag from "../../assets/cricket.png";
import { useCricket } from "./CricketContext";
import loader from "../../assets/Loader.gif";
import ReactCountryFlag from "react-country-flag";

const Cricket = () => {
  const navigate = useNavigate();
  const { selectedDate, resData, isLoading } = useCricket();

  const handleClick = (game) => {
    const match = game;

    if (match.status.code === 0) {
      navigate("/cricket/league/today", { state: { match } });
    } else if (match.status.code === 21 || match.status.code === 100) {
      navigate("/cricket/league/result", { state: { match } });
    }
  };

  const handleRoute = (games) => {
    const match = games;
    navigate("/cricket/league", { state: { match } });
  };

  const convertTimestampToDate = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const dateString = dateObject.toISOString().split("T")[0];
    const timeString = dateObject.toTimeString().split(" ")[0];
    return `${dateString} ${timeString}`;
  };

  const convertTimestampToDateOnly = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const dateString = dateObject.toISOString().split("T")[0];
    return dateString;
  };

  const gamesByTournament = {};

  resData.forEach((game) => {
    const tournamentId = game.tournament?.uniqueTournament?.id;
    if (tournamentId) {
      if (!gamesByTournament[tournamentId]) {
        gamesByTournament[tournamentId] = [];
      }
      gamesByTournament[tournamentId].push(game);
    }
  });

  return (
    <div className="my-2">
      {isLoading && !resData.length ? (
        <img src={loader} alt="loader" className="img-fluid" />
      ) : (
        Object.entries(gamesByTournament).map(([tournamentId, games]) => {
          // Filter out games with status code 21
          const filteredGames = games.filter((game) => game.status.code !== 21);

          const tournamentGamesForSelectedDate = filteredGames.filter(
            (game) =>
              convertTimestampToDateOnly(game.startTimestamp) ===
              selectedDate.toLocaleDateString('en-CA')
          );

          if (tournamentGamesForSelectedDate.length === 0) return null;

          const tournamentData = {
            name: tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
              ?.name,
            country:
              tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
                ?.category?.country?.name,
            flag: tournamentGamesForSelectedDate[0].tournament?.uniqueTournament
              ?.category?.country?.alpha2,
            games: tournamentGamesForSelectedDate,
          };

          return (
            <div key={tournamentId} className="px-4">
              <div className="d-flex">
                <button
                  onClick={() => handleRoute(games)}
                  className="btn btn-secondary w-100 d-flex align-items-center gap-2 mx-2 p-1"
                  style={{ backgroundColor: "#111" }}
                >
                  {tournamentData.flag ? (
                    <ReactCountryFlag
                      countryCode={tournamentData.flag}
                      svg
                      style={{
                        width: "1.5em",
                        height: "1.5em",
                      }}
                      title={tournamentData.flag}
                    />
                  ) : (
                    <img
                      src={countryFlag}
                      alt="flag"
                      style={{ width: "1.5em", height: "1.5em" }}
                    />
                  )}

                  <div className="mx-2 text-start">
                    <div>{tournamentData.name}</div>
                    <div>{tournamentData.country}</div>
                  </div>
                </button>
              </div>
              {tournamentGamesForSelectedDate.map((game, index) => (
                <button
                  key={index}
                  className="card-body btn w-100 text-start text-white p-3 my-2"
                  onClick={() => handleClick(game)}
                >
                  <span className="fsize">{game?.tournament.name}</span>
                  <br />
                  <p className="fsize">
                    {convertTimestampToDate(game.startTimestamp)} -{" "}
                    {game.status.description}
                  </p>
                  <div className="d-flex justify-content-between mt-3">
                    <section>
                      <h6>{game.homeTeam.name}</h6>
                      <h6>{game.awayTeam.name}</h6>
                    </section>
                    {game.status.code === 100 && (
                      <section>
                        {
                          game.homeScore?.innings?.inning2 ? 
                          <h6>
                            {game.homeScore?.display} & {game.homeScore?.innings?.inning2?.score}/
                            {game.homeScore?.innings?.inning2?.wickets}{" "}
                            <span className="text-secondary">
                              ({game.homeScore?.innings?.inning2?.overs})
                            </span>
                          </h6> :
                          <h6>
                            {game.homeScore?.display}/
                            {game.homeScore?.innings?.inning1?.wickets}{" "}
                            <span className="text-secondary">
                              ({game.homeScore?.innings?.inning1?.overs})
                            </span>
                          </h6>
                        }
                        {
                          game.awayScore?.innings?.inning2 ? 
                          <h6>
                            {game.awayScore?.display} & {game.awayScore?.innings?.inning2?.score}/
                            {game.awayScore?.innings?.inning1?.wickets}{" "}
                            <span className="text-secondary">
                              ({game.awayScore?.innings?.inning1?.overs})
                            </span>
                          </h6>:
                          <h6>
                            {game.awayScore?.display}/
                            {game.awayScore?.innings?.inning1?.wickets}{" "}
                            <span className="text-secondary">
                              ({game.awayScore?.innings?.inning1?.overs})
                            </span>
                          </h6>
                        }
                      </section>
                    )}
                    {game.status.code === 140 && (
                      <section>
                        {
                          game.homeScore?.innings?.inning2 ? 
                          <h6>
                            {game.homeScore?.display} & {game.homeScore?.innings?.inning2?.score}/
                            {game.homeScore?.innings?.inning2?.wickets}{" "}
                            <span className="text-secondary">
                              ({game.homeScore?.innings?.inning2?.overs})
                            </span>
                          </h6> :
                          <h6>
                            {game.homeScore?.display}/
                            {game.homeScore?.innings?.inning1?.wickets}{" "}
                            <span className="text-secondary">
                              ({game.homeScore?.innings?.inning1?.overs})
                            </span>
                          </h6>
                        }
                        {
                          game.awayScore?.innings?.inning2 ? 
                          <h6>
                            {game.awayScore?.display} & {game.awayScore?.innings?.inning2?.score}/
                            {game.awayScore?.innings?.inning1?.wickets}{" "}
                            <span className="text-secondary">
                              ({game.awayScore?.innings?.inning1?.overs})
                            </span>
                          </h6>:
                          <h6>
                            {game.awayScore?.display}/
                            {game.awayScore?.innings?.inning1?.wickets}{" "}
                            <span className="text-secondary">
                              ({game.awayScore?.innings?.inning1?.overs})
                            </span>
                          </h6>
                        }
                      </section>
                    )}
                  </div>
                  {game.status.code === 100 && (
                    <h6 className="fsize text-center">{game.note}</h6>
                  )}
                </button>
              ))}
            </div>
          );
        })
      )}
    </div>
  );
};

export default Cricket;
