import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { OlderMatches } from "./OlderMatches";
import { FutureMatches } from "./FutureMatches";

const MatchHome = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("matchHomeActiveTab") || "Futures"
  );
  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    localStorage.setItem("matchHomeActiveTab", activeTab);

    return () => {
      localStorage.removeItem("matchHomeActiveTab");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="d-flex mx-3">
      <div className="w-100">
        <hr />
        <div className="d-flex gap-2 mb-2">
          <Link
            to="/cricket/league/future"
            state={{ match }}
            className={`link-eff btn btn-sm p-1 ${
              activeTab === "Futures" ? "active" : ""
            }`}
            onClick={() => handleClick("Futures")}
          >
            Futures
          </Link>
          <Link
            to="/cricket/league/last_matches"
            state={{ match }}
            className={`link-eff btn btn-sm p-1 ${
              activeTab === "Results" ? "active" : ""
            }`}
            onClick={() => handleClick("Results")}
          >
            Results
          </Link>
        </div>
        {activeTab === "Futures" && <FutureMatches />}
        {activeTab === "Results" && <OlderMatches />}
      </div>
    </div>
  );
};

export default MatchHome;
