import React from "react";
import VolleyHome from "./VolleyHome";
import VolleyLeagues from "./Leagues/VolleyLeagues";
import VolleyMatches from "./Matches/VolleyMatches";
import VolleyFutureMatch from "./Matches/VolleyFutureMatch";
import VolleyResult from "./otherFiles/VolleyResult";
import VolleyTodayMatch from "./Matches/VolleyTodayMatch";
import { Route, Routes } from "react-router-dom";
import VolleyLive from "./LiveScores/VolleyLive";
import PointTable from "./otherFiles/PointTable";
import VolleyOverview from "./Matches/VolleyOverview";
import Error from "../Error";

const VolleyIndex = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<VolleyHome />} />
        <Route path={`/:dated`} element={<VolleyHome />} />
        <Route path="/league/*" element={<VolleyLeagues />} />
        <Route path="/match" element={<VolleyMatches />} />
        <Route path="/overview" element={<VolleyOverview />} />
        <Route path="/table" element={<PointTable />} />
        <Route path="/live" element={<VolleyLive />} />
        <Route path="/future" element={<VolleyFutureMatch />} />
        <Route path="/league/result/*" element={<VolleyResult />} />
        <Route path="/league/today/*" element={<VolleyTodayMatch />} />
        {/* <Route path="*" element={<Error />} /> */}
      </Routes>
    </div>
  );
};

export default VolleyIndex;
