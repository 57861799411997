import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import countryFlag from "../../../assets/volleyball.png";
import VolleyOverview from "../Matches/VolleyOverview";
import VolleyMatches from "../Matches/VolleyMatches";

const VolleyLeagues = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("la") || "Overview"
  );
  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    localStorage.setItem("la", activeTab);

    return () => {
      localStorage.removeItem("la");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="d-flex">
        <div
          className="d-flex align-items-center gap-2 mx-2 p-1"
          style={{ backgroundColor: "#111" }}
        >
          <img src={countryFlag} alt="flag" style={{ width: "20px" }} />
          <div className="mx-2">
            <div>{match[0]?.tournament?.uniqueTournament?.name}</div>
            <div>
              {match[0]?.tournament?.uniqueTournament?.category?.country?.name}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="card-title d-flex gap-2 p-2">
        <Link
          to="/volleyball/league/overview"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Overview" ? "active" : ""
          }`}
          onClick={() => handleClick("Overview")}
        >
          Overview
        </Link>
        <Link
          to="/volleyball/league/match"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "VolMatch" ? "active" : ""
          }`}
          onClick={() => handleClick("VolMatch")}
        >
          Matches
        </Link>
      </div>
      {activeTab === "Overview" && <VolleyOverview />}
      {activeTab === "VolMatch" && <VolleyMatches />}
      <br />
    </div>
  );
};

export default VolleyLeagues;
