import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";

const HockFutureMatch = () => {
  const location = useLocation();
  const { match } = location.state || {};
  const navigate = useNavigate();

  const handleClick = (match) => {
    navigate("/hockey/leagues/todaymatch", { state: { match } });
  };

  const convertTimestamp = (timestamp) => {
    const dateObject = new Date(timestamp * 1000);
    return dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const convertTimestampToDate = (timestamp) => {
    const dateObject = new Date(timestamp * 1000);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return dateObject.toLocaleDateString(undefined, options);
  };
  
  const futureMatch = match.filter((item)=>item.status.code === 0)
  
  return (
    <div className="p-3">
      {/* {match?.status?.code === 100 ? ( */}
        { futureMatch.map((game, index) => (
          <div key={index}>
              <button
                className="card-body text-white btn w-100 align-items-center p-3"
                onClick={() => handleClick(game)}
              >
                <div className="d-flex align-items-center gap-2 mt-2">
                  <section>
                    <span className="fsize">
                      <p className="fsize">{convertTimestamp(game.startTimestamp)}</p>
                      {game.status.description}
                    </span>
                  </section>
                  <section>
                    <div className="d-flex gap-2">
                      <ReactCountryFlag
                        countryCode={game?.homeTeam?.country?.alpha2}
                        svg
                        style={{
                          width: "1.3em",
                          height: "1.3em",
                        }}
                        title={game?.homeTeam?.country?.alpha2}
                      />
                      <p>{game.homeTeam.name}</p>
                    </div>
                    <div className="d-flex gap-2 mt-2">
                      <ReactCountryFlag
                        countryCode={game?.awayTeam?.country?.alpha2}
                        svg
                        style={{
                          width: "1.3em",
                          height: "1.3em",
                        }}
                        title={game?.awayTeam?.country?.alpha2}
                      />
                      <p>{game.awayTeam.name}</p>
                    </div>
                    </section>
                </div>
              </button>
          </div>
        ))}
    </div>
  );
};

export default HockFutureMatch;
