import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { KeyProvider } from "../../KeyProvider";

const FootInfo = () => {
  const [resData, setResData] = useState({});
  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    const getSportsData = async () => {
      const defaultUrl =
        "https://os-sports-perform.p.rapidapi.com/v1/events/data";
      const defaultParams = {
        event_id: match.id
      };
      const options = {
        headers: { 
          "X-RapidAPI-Key": KeyProvider,
          "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
        },
      };
      try {
        const response = await axios.get(defaultUrl, {
          params: defaultParams,
          headers: options.headers,
        });
        setResData(response.data.data);
      } catch (error) {
        console.error("Error fetching sports data:", error);
      }
    };
    getSportsData();
  }, [match.id]);

  const convertTimestampToDate = (timestamp) => {
    if (!timestamp) return "Invalid Date";
    const dateObject = new Date(timestamp * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = dateObject.toLocaleDateString(undefined, options);
    const time = dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${date} ${time}`;
  };

  return (
    <div>
      <h5 className="mx-2">Match Info</h5>
      <div className="d-flex mx-4">
        {resData.startTimestamp ? (
          <div className="d-flex gap-5 mx-4">
            <div>
              <p>Event</p>
              <p>Starts</p>
              <p>Venue</p>
              <p>Country</p>
              <p>Match Status</p>
            </div>
            <div>
              <p>{resData.tournament?.name}</p>
              <p>{convertTimestampToDate(resData.startTimestamp)}</p>
              <p>
                {resData.venue?.stadium?.name || "N/A"},{" "}
                {resData.venue?.city?.name || "N/A"}
              </p>
              <p>
                {resData.tournament?.uniqueTournament?.category?.name || "N/A"}
              </p>
              <p>
                {resData.status
                  ? resData.status?.description
                  : "Unknown Status"}
              </p>
            </div>
          </div>
        ) : (
          <div
            className="spinner-grow"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="sr-only"></span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FootInfo;
