import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";

const HockOlderMatch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { match } = location.state || {};

  const handleClick = (match) => {
    navigate("/hockey/leagues/result", { state: { match } });
  };

  const convertTimestampToDate = (timestamp) => {
    const dateObject = new Date(timestamp * 1000);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return dateObject.toLocaleDateString(undefined, options);
  };
  
  const resultedGames = match.filter((item)=>item.status.code === 100)

  return (
    <div className="p-3">
      {/* {match?.status?.code === 100 ? ( */}
        { resultedGames.map((game, index) => (
          <div key={index}>
              <button
                className="card-body text-white btn w-100 align-items-center p-3"
                onClick={() => handleClick(game)}
              >
                <div className="d-flex justify-content-between mt-2">
                  <section className="mx-2 text-start">
                    <div className="d-flex gap-2">
                      <ReactCountryFlag
                        countryCode={game?.homeTeam?.country?.alpha2}
                        svg
                        style={{
                          width: "1.3em",
                          height: "1.3em",
                        }}
                        title={game?.homeTeam?.country?.alpha2}
                      />
                      <p>{game.homeTeam.name}</p>
                    </div>
                    <div className="d-flex gap-2 mt-2">
                      <ReactCountryFlag
                        countryCode={game?.awayTeam?.country?.alpha2}
                        svg
                        style={{
                          width: "1.3em",
                          height: "1.3em",
                        }}
                        title={game?.awayTeam?.country?.alpha2}
                      />
                      <p>{game.awayTeam.name}</p>
                    </div>
                  </section>
                  <section className="mx-4">
                    <p>{game.homeScore.display}</p>
                    <p>{game.awayScore.display}</p>
                  </section>
                </div>
                <span className="fsize">
                  {convertTimestampToDate(game.startTimestamp)}{" "}
                  {game.status.description}
                </span>
              </button>
          </div>
        ))}
    </div>
  );
};

export default HockOlderMatch;
