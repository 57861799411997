import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { KeyProvider } from "../../../KeyProvider";

const Live = () => {
  const navigate = useNavigate();
  const convertTimestampToDate = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const dateString = dateObject.toISOString().split("T")[0];
    const timeString = dateObject.toTimeString().split(" ")[0];
    return `${dateString} ${timeString}`;
  };

  const [resData, setResData] = useState([]);

  const date = new Date().toISOString().split("T")[0];
  useEffect(() => {
    const getSportsData = async () => {
      const defaultUrl = 'https://os-sports-perform.p.rapidapi.com/v1/events/schedule/live';
      const defaultParams = {
        date: date,
        sport_id: '62'
      };
      const options = {
        headers: {
          'X-RapidAPI-Key': KeyProvider ,
          'X-RapidAPI-Host': 'os-sports-perform.p.rapidapi.com'
        }
      };
      try {
        const response = await axios.get(defaultUrl, {
          params: defaultParams,
          headers: options.headers
        });
        setResData(response.data.data);
      } catch (error) {
        console.error("Error fetching sports data:", error);
      }
    };
    getSportsData();
  }, [date]);

  const handleClick = (match) => {
    navigate("/cricket/league/result", { state: { match } });
  };

  return (
    <div className="container">
      <h5 className="text-secondary">Live Score</h5>

      {resData.length > 0 ? (
        resData.map((match, index) => (
          <button
            key={index}
            className="card-body btn w-100 text-start text-white mb-2 p-3"
            onClick={() => handleClick(match)}
          >
            <span className="fsize">{match.tournament.name}</span>
            <br />
            <p className="fsize">
              {convertTimestampToDate(match.startTimestamp)} -{" "}
              {match.status.description}
            </p>
            <div className="d-flex justify-content-between mx-2 mt-3">
              <section>
                <h6>{match.homeTeam.name}</h6>
                <h6>{match.awayTeam.name}</h6>
              </section>
              <section>
                {
                  match.homeScore?.innings?.inning2 ? 
                  <h6>
                    {match.homeScore?.display} & {match.homeScore?.innings?.inning2?.score}/
                    {match.homeScore?.innings?.inning2?.wickets}{" "}
                    <span className="text-secondary">
                      ({match.homeScore?.innings?.inning2?.overs})
                    </span>
                  </h6> :
                  <h6>
                    {match.homeScore?.display}/
                    {match.homeScore?.innings?.inning1?.wickets}{" "}
                    <span className="text-secondary">
                      ({match.homeScore?.innings?.inning1?.overs})
                    </span>
                  </h6>
                }
                {
                  match.awayScore?.innings?.inning2 ? 
                  <h6>
                    {match.awayScore?.display} & {match.awayScore?.innings?.inning2?.score}/
                    {match.awayScore?.innings?.inning1?.wickets}{" "}
                    <span className="text-secondary">
                      ({match.awayScore?.innings?.inning1?.overs})
                    </span>
                  </h6>:
                  <h6>
                    {match.awayScore?.display}/
                    {match.awayScore?.innings?.inning1?.wickets}{" "}
                    <span className="text-secondary">
                      ({match.awayScore?.innings?.inning1?.overs})
                    </span>
                  </h6>
                }
              </section>
            </div>
          </button>
        ))
      ) : (
        <p>No Live Matches At This Time</p>
      )}
    </div>
  );
};

export default Live;
