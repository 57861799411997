import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import countryFlag from '../../../assets/India_Flag.png'
import BasketOverview from '../Matches/BasketOverview';
import BasketMatches from '../Matches/BasketMatches';

const LeaguesBasket = () => {
    
    const [activeTab, setActiveTab] = useState(localStorage.getItem("baskLeague") || "Overview");
    const location = useLocation()
    const { match } = location.state || {};

    useEffect(() => {
      localStorage.setItem("baskLeague", activeTab);
  
      return () => {
        localStorage.removeItem("baskLeague");
      };
    }, [activeTab]);

    const handleClick = (tab) => {
      setActiveTab(tab);
    };
  
    return (
      <div>
        <div className="card-title d-flex gap-2 px-2">
          <Link
            to="/basketball/league/overview"
            state={{ match }}
            className={`link-eff btn btn-sm p-1 ${activeTab === "Overview" ? "active" : ""}`}
            onClick={() => handleClick("Overview")}
          >
            Overview
          </Link>
          <Link
            to="/basketball/league/match"
            state={{ match }}
            className={`link-eff btn btn-sm p-1 ${activeTab === "BaskMatch" ? "active" : ""}`}
            onClick={() => handleClick("BaskMatch")}
          >
            Matches
          </Link>
        </div>
        {activeTab === "Overview" && <BasketOverview />}
        {activeTab === "BaskMatch" && <BasketMatches />}
        <br />
      </div>
    )
}

export default LeaguesBasket