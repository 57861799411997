import React from "react";
import { Route, Routes } from "react-router-dom";
import BaseHome from "./BaseHome";
import BaseLeagues from "./Leagues/BaseLeagues";
import BaseMatches from "./Matches/BaseMatches";
import BaseFutureMatch from "./Matches/BaseFutureMatch";
import BaseResult from "./otherFiles/BaseResult";
import BaseTodayMatch from "./Matches/BaseTodayMatch";
import BaseOverview from "./Matches/BaseOverview";
import BaseLiveScore from "./LiveScores/BaseLiveScore";
import LineUps from "./otherFiles/LineUps";
import Error from "../Error";

const BaseballIndex = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<BaseHome />} />
        <Route path={`/:dated`} element={<BaseHome />} />
        <Route path="/league/*" element={<BaseLeagues />} />
        <Route path="/match" element={<BaseMatches />} />
        <Route path="/players" element={<LineUps />} />
        <Route path="/live" element={<BaseLiveScore />} />
        <Route path="/overview" element={<BaseOverview />} />
        <Route path="/future_match" element={<BaseFutureMatch />} />
        <Route path="/league/result/*" element={<BaseResult />} />
        <Route path="/league/today/*" element={<BaseTodayMatch />} />
        <Route path="/past_matches" element={<BaseTodayMatch />} />
        {/* <Route path="*" element={<Error />} /> */}
      </Routes>
    </div>
  );
};

export default BaseballIndex;
