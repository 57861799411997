import React from "react";
import { useLocation } from "react-router-dom";

const VolleyInfo = () => {
  const location = useLocation();
  const { match } = location.state || {};

  const convertTimestampToDate = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const dateString = dateObject.toISOString().split("T")[0];
    const timeString = dateObject.toTimeString().split(" ")[0];
    return `${dateString} ${timeString}`;
  };

  return (
    <div className="py-2">
      <h4 className="mx-2">Match Info</h4>
      <div className="d-flex justify-content-center gap-4 mpx-4">
        <div className="d-flex gap-3">
          <p>{convertTimestampToDate(match?.startTimestamp)}</p>
        </div>
        {/* <div>
          <p>Tim Robinson (England)</p>
        </div>
        <div>
          <p>Kenilworth Road</p>
        </div> */}
      </div>
    </div>
  );
};

export default VolleyInfo;
