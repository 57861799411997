import React from "react";
import { useLocation } from "react-router-dom";

const VolleySummary = () => {
  const location = useLocation();
  const { match } = location.state || {};

  return (
    <div className="px-3">
      {match?.status?.code === 0 ? (
        <p>Event Not Started Yet</p>
      ) : (
        <section className="m-2">
          <table className="table table-dark border border-secondary p-2 border-opacity-25">
            <thead>
              <tr>
                <th className="">Team</th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>T</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{match?.homeTeam?.name}</td>
                <td>{match?.homeScore?.period1}</td>
                <td>{match?.homeScore?.period2}</td>
                <td>{match?.homeScore?.period3}</td>
                <td>{match?.homeScore?.period4}</td>
                <td>{match?.homeScore?.display}</td>
              </tr>
              <tr>
                <td>{match?.awayTeam?.name}</td>
                <td>{match?.awayScore?.period1}</td>
                <td>{match?.awayScore?.period2}</td>
                <td>{match?.awayScore?.period3}</td>
                <td>{match?.awayScore?.period4}</td>
                <td>{match?.awayScore?.display}</td>
              </tr>
            </tbody>
          </table>
        </section>
      )}
    </div>
  );
};

export default VolleySummary;
