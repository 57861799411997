import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { KeyProvider } from "../../KeyProvider";

const VolleyContext = createContext();

export const useVolley = () => useContext(VolleyContext);

export const VolleyProvider = ({ children, selectedDate }) => {
  const [resData, setResData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCategoryIds = async () => {
    try {
      const response = await axios.get("https://os-sports-perform.p.rapidapi.com/v1/categories?sport_id=23", {
        headers: {
          "X-RapidAPI-Key": KeyProvider,
          "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
        },
      });
      const fetchedCategories = response?.data?.data || [];
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Error fetching category IDs:", error);
    }
  };

  const fetchSportsDataForBatch = async (date, batch) => {
    const data = await Promise.all(batch.map(async (category) => {
      const defaultUrl = "https://os-sports-perform.p.rapidapi.com/v1/events/schedule/category";
      const defaultParams = {
        date: date.toISOString().split('T')[0],
        sport_id: "23",
        category_id: category.id,
      };

      const options = {
        headers: {
          "X-RapidAPI-Key": KeyProvider,
          "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
        },
        params: defaultParams,
      };

      try {
        const response = await axios.get(defaultUrl, options);
        return response?.data?.data || [];
      } catch (error) {
        console.error(`Error fetching data for category ${category.id}:`, error);
        return [];
      }
    }));
    return data.flat();
  };

  const fetchAllSportsData = async (date, categoryIds) => {
    setIsLoading(true);
    const batchSize = 4;
    let combinedData = [];

    for (let i = 0; i < categoryIds.length; i += batchSize) {
      const batch = categoryIds.slice(i, i + batchSize);
      const batchData = await fetchSportsDataForBatch(date, batch);
      combinedData = [...combinedData, ...batchData];

      if (i + batchSize < categoryIds.length) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }

    setResData(combinedData);
    setIsLoading(false);
  };

  const debouncedFetchAllSportsData = debounce(fetchAllSportsData, 500);

  useEffect(() => {
    fetchCategoryIds();
  }, []);

  useEffect(() => {
    if (categories.length) {
      debouncedFetchAllSportsData(selectedDate, categories);
    }
    return () => {
      debouncedFetchAllSportsData.cancel();
    };
  }, [selectedDate, categories]);

  return (
    <VolleyContext.Provider value={{ selectedDate, resData, isLoading, categories }}>
      {children}
    </VolleyContext.Provider>
  );
};
