import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HockInfo from "./HockInfo";
import Summary from "./Summary";
import ReactCountryFlag from "react-country-flag";
import PointTable from "./PointTable";

const HockResult = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("la") || "Summary"
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    localStorage.setItem("la", activeTab);

    return () => {
      localStorage.removeItem("la");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const handleRoute = () => {
    navigate("/hockey");
  };

  const convertTimestampToTimeOnly = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const timeString = dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return timeString;
  };

  return (
    <div>
      <div className="d-flex">
        <button
          onClick={handleRoute}
          className="btn btn-secondary w-100 d-flex align-items-center gap-2 mx-2 p-1"
          style={{ backgroundColor: "#111" }}
        >
          <ReactCountryFlag
            countryCode={match?.tournament?.category?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.tournament?.category?.country?.alpha3}
          />
          <div className="mx-2 text-start">
            <p>{match?.tournament?.name}</p>
            <p>{match?.tournament?.category?.name}</p>
          </div>
        </button>
      </div>
      <hr />
      <div className="bg-dark mx-2 d-flex justify-content-evenly">
        <p className="p-2">
          {/* <img src={countryFlag} alt="flag" style={{width:'20px'}} /> */}
          {match?.homeTeam?.name}
        </p>
        <div className="d-flex gap-1 align-items-center">
          {/* {match ? <p className="fsize">{convertTimestampToTimeOnly(match?.startTimestamp)}</p> : <p className="fsize">FT</p> } */}
          <p>{match?.homeScore?.display}</p>-<p>{match?.awayScore?.display}</p>
        </div>
        <p className="p-2">
          {/* <img src={countryFlag} alt="flag" style={{width:'20px'}} /> */}
          {match?.awayTeam?.name}
        </p>
      </div>
      <hr />
      <div className="my-2 d-flex gap-2 mx-2">
        <Link
          to="/hockey/leagues/result/info"
          state={{ match }}
          className={`link-eff btn btn-sm p-1 ${
            activeTab === "Finfo" ? "active" : ""
          }`}
          onClick={() => handleClick("Finfo")}
        >
          Info
        </Link>
        <Link
          to="/hockey/leagues/result/summary"
          state={{ match }}
          className={`link-eff btn btn-sm p-1 ${
            activeTab === "Summary" ? "active" : ""
          }`}
          onClick={() => handleClick("Summary")}
        >
          Summary
        </Link>
        <Link
          to="/hockey/leagues/result/table"
          state={{ match }}
          className={`link-eff btn btn-sm p-1 ${
            activeTab === "Table" ? "active" : ""
          }`}
          onClick={() => handleClick("Table")}
        >
          Table
        </Link>
      </div>
      {activeTab === "Finfo" && <HockInfo />}
      {activeTab === "Summary" && <Summary />}
      {activeTab === "Table" && <PointTable />}
    </div>
  );
};

export default HockResult;
