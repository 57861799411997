import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { KeyProvider } from "../../KeyProvider";

const PointTable = () => {
  const [resData, setResData] = useState([]);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { match } = location.state || {};
  const date = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const getSportsData = async () => {
      const defaultUrl =
        "https://os-sports-perform.p.rapidapi.com/v1/seasons/standings";
      const defaultParams = {
        date: date,
        standing_type: "total",
        seasons_id: match.season.id,
        unique_tournament_id: match.tournament.uniqueTournament.id,
      };

      const options = {
        headers: {
          "X-RapidAPI-Key": KeyProvider ,
          "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.get(defaultUrl, {
          params: defaultParams,
          headers: options.headers,
        });

        setResData(response.data.data);
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching sports data:", error);
        setIsLoading(false)
      }
    };

    getSportsData();
  }, [date]);

  return (
    <div>
      <section className="mx-4 my-2">
      {isLoading ? (
          <div
          className="spinner-grow"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only"></span>
        </div>
        ) : (
          resData.map((groupData, index) => (
            <div key={index}>
              {/* Display the group name */}
              <h5 className="">{groupData.name}</h5>
              <table className="table table-dark">
                <thead>
                  <tr>
                    <th scope="col" className="text-secondary">#</th>
                    <th scope="col" className="text-secondary">Team</th>
                    <th scope="col" className="text-secondary">M</th>
                    <th scope="col" className="text-secondary">W</th>
                    <th scope="col" className="text-secondary">L</th>
                    <th scope="col" className="text-secondary">N/R</th>
                    <th scope="col" className="text-secondary">Points</th>
                    <th scope="col" className="text-secondary">NRR</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Display each team's data */}
                  {groupData.rows.map((row, rowIndex) => (
                    <tr key={`${groupData.id}-${row.id}`}>
                      <th scope="row" className="text-secondary">
                        {rowIndex + 1}
                      </th>
                      <td>{row.team.name}</td>
                      <td>{row.matches}</td>
                      <td>{row.wins}</td>
                      <td>{row.losses}</td>
                      <td>{row.noResult}</td>
                      <td>{row.points}</td>
                      <td>{row.netRunRate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))
        )}
      </section>
    </div>
  );
};

export default PointTable;
