import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FootInfo from "../FootInfo";
import Table from "../Table";
import ReactCountryFlag from "react-country-flag";

const FootTodaysMatch = () => {
  const location = useLocation();
  const { match } = location.state || {};

  const [activeTab, setActiveTab] = useState("Info");
  const navigate = useNavigate();

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const handleRoute = () => {
    navigate("/football");
  };

  const convertTimestampToTimeOnly = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const timeString = dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return timeString;
  };

  const DateOnly = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { month: "short", day: "2-digit" };
    const dateString = date.toLocaleDateString("en-US", options);
    return dateString;
  };

  return (
    <div className="p-3">
        <div className="d-flex">
          <button
            onClick={handleRoute}
            className="btn btn-secondary w-100 d-flex align-items-center gap-2 mx-2 p-1"
            style={{ backgroundColor: "#111" }}
          >
            <ReactCountryFlag
              countryCode={match?.tournament?.category?.country?.alpha2}
              svg
              style={{
                width: "1.5em",
                height: "1.5em",
              }}
              title={match?.tournament?.category?.country?.alpha3}
            />
            <div className="mx-2 text-start">
              <p>{match?.tournament?.name}</p>
              <p>{match?.tournament?.category?.country?.name}</p>
            </div>
          </button>
        </div>
        <hr />
        <div className="bg-dark mx-2 p-3 d-flex justify-content-evenly align-items-center">
          <div className="text-center">
            <ReactCountryFlag
              countryCode={match?.homeTeam?.country?.alpha2}
              svg
              style={{
                width: "1.5em",
                height: "1.5em",
              }}
              title={match?.homeTeam?.country?.alpha3}
            />
            <p className="p-2">{match?.homeTeam?.name}</p>
          </div>
          <div className="text-center">
            <h6>{convertTimestampToTimeOnly(match?.startTimestamp)}</h6>
            <p className="fsize">{DateOnly(match?.startTimestamp)}</p>
          </div>
          <div className="text-center">
            <ReactCountryFlag
              countryCode={match?.awayTeam?.country?.alpha2}
              svg
              style={{
                width: "1.5em",
                height: "1.5em",
              }}
              title={match?.awayTeam?.country?.alpha3}
            />
            <p className="p-2">{match?.awayTeam?.name}</p>
          </div>
        </div>
        <div className="d-flex gap-2 mx-2 mb-3">
          <Link
            to="/football/league/todaymatch/info"
            state={{ match }}
            className={`link-eff btn btn-sm p-2 ${
              activeTab === "Info" ? "active" : ""
            }`}
            onClick={() => handleClick("Info")}
          >
            Info
          </Link>
          <Link
            to="/football/league/todaymatch/table"
            state={{ match }}
            className={`link-eff btn btn-sm p-2 ${
              activeTab === "Table" ? "active" : ""
            }`}
            onClick={() => handleClick("Table")}
          >
            Table
          </Link>
          <Link
            to="/football/league/todaymatch/table"
            state={{ match }}
            className={`link-eff btn btn-sm p-2 ${
              activeTab === "Lineups" ? "active" : ""
            }`}
            onClick={() => handleClick("Lineups")}
          >
            Line-Ups
          </Link>
        </div>
        {activeTab === "Info" && <FootInfo />}
        {activeTab === "Table" && <Table />}
        {activeTab === "Lineups" && (
          <p>Teams will be displayed after start match</p>
        )}
    </div>
  );
};

export default FootTodaysMatch;
