import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const BasketFutureMatch = () => {
  const location = useLocation();
  const { match } = location.state || {};
  const navigate = useNavigate();

  const handleClick = (match) => {
    navigate("/basketball/league/today", { state: { match } });
  };

  const convertTimestamp = (timestamp) => {
    const dateObject = new Date(timestamp * 1000);
    return dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const futuredMatch = match.filter((match) => match.status.code === 0);

  return (
    <div className="p-3">
      {futuredMatch && futuredMatch.length > 0 ? (
        futuredMatch.map((match, index) => (
          <div key={index} className="mx-2">
            <button
              className="card-body btn w-100 text-white d-flex align-items-center mb-2 p-3"
              onClick={() => handleClick(match)}
            >
              <section className="fsize">
                <span className="fsize">
                  <p className="fsize">
                    {convertTimestamp(match.startTimestamp)}
                  </p>
                  {match?.status?.description}
                </span>
              </section>
              <section className="mx-4">
                <h6>{match?.homeTeam?.name}</h6>
                <h6>{match?.awayTeam?.name}</h6>
              </section>
            </button>
          </div>
        ))
      ) : (
        <p>There is No Matches Today</p>
      )}
    </div>
  );
};

export default BasketFutureMatch;
