// import React, { useEffect, useState } from "react";
// import Region from "./region/Region";
// import Competition from "./competition/Competition";

// var countriesData = [];
// export const sideMenuData = {
//   INITIAL: "INITIAL",
//   REGION: "REGION",
//   COMP: "COMP",
// };

// const Sidebar = ({gameId}) => {
//   const [search, setSearch] = useState("");
//   const [data, getCountriesData] = useState([]);
//   const [showRegion, setShowRegion] = useState(false);
//   const [showCompetion, setShowCompetion] = useState(false);

//   // Temp
//   const [selectedMenu, setSelectedMenu] = useState(sideMenuData.INITIAL);

//   const handleGotoInitial = () => {

//   console.log("exceuted initail" );
//     setSelectedMenu(sideMenuData.INITIAL);
//   };

//   const apiStatusConstants = {
//     intial: "INITIAL",
//     sucess: "SUCCESS",
//     failuer: "FAILURE",
//   };

//   const [apiResponse, setApiResponse] = useState({
//     status: apiStatusConstants.intial,
//     data: null,
//     errorMsg: null,
//   });

//   // useEffect(() => {
//   //   const getSportsData = async () => {
//   //     setApiResponse({
//   //       data: null,
//   //       errorMsg: null,
//   //     });

//   //     const options = {
//   //       method: "GET",
//   //       headers: {
//   //         "X-RapidAPI-Key":
//   //           "72d2cd5a55msh3e8790d3b499679p1279b2jsn0971fc6c5d44",
//   //         "X-RapidAPI-Host": "livescore-sports.p.rapidapi.com",
//   //       },
//   //     };
//   //     const response = await fetch(
//   //       "https://livescore-sports.p.rapidapi.com/v1/meta/specification?locale=EN",
//   //       options
//   //     );
//   //     const responseData = await response.json();
//   //     console.log(responseData);
//   //     const { DATA } = responseData;
//   //     const { cricket_static, explore_page, league_table, notification } = DATA;
//   //     const { Countries } = cricket_static;
//   //     countriesData = Countries["4"];
//   //     const countrieNames = Object.values(countriesData);
//   //     getCountriesData(countrieNames);
//   //   };
//   //   getSportsData();
//   // }, []);

//   const renderSuccessView = () => {};
//   const renderFailureView = () => {};

//   const renderCricketDataView = () => {
//     const { status } = apiResponse;
//     switch (status) {
//       case apiStatusConstants.sucess:
//         return renderSuccessView();
//       case apiStatusConstants.failuer:
//         return renderFailureView();
//       default:
//         return null;
//     }
//   };

//   renderCricketDataView();

//   const filteredCountries = data.filter((item) =>
//     item.toLowerCase().includes(search.toLowerCase())
//   );

//   const handleSearchChange = (event) => {
//     event.preventDefault();
//     setSearch(event.target.value);
//   };

//   //   const seacrhButton = (event) => {
//   //     event.preventDefault();
//   //   };

//   return (
//     <div className="col-12 countrys-search-container border border-secondary px-2">
//       <form className="example m-2 regionHover" action="action_page.php">
//         <input
//           onChange={handleSearchChange}
//           type="text"
//           placeholder="Search.."
//           name="search"
//           value={search}
//           className="inputField"
//         />
//         {/* <button type="submit" onClick={seacrhButton}>
//                   <i className="fa fa-search"></i>
//                 </button> */}
//       </form>
//       {/* <div>
//           {filteredCountries
//             .map((item,index) => (
//               <div key={index}>
//                 <p className="counties-display-container">{item}</p>
//               </div>
//             ))}
//         </div> */}

//       {showRegion ? (
//         <></>
//       ) : (
//         <>
//           {(selectedMenu === sideMenuData.INITIAL ||
//             selectedMenu === sideMenuData.REGION) && (
//             <Region
//               handleGotoInitial={handleGotoInitial}
//               selectedMenu={selectedMenu}
//               setSelectedMenu={setSelectedMenu}
//               setShowRegion={setShowRegion}
//               setShowCompetion={setShowCompetion}
//               gameId={gameId}
//             />
//           )}
//         </>
//       )}

//       {showCompetion ? (
//         <></>
//       ) : (
//         <>
//           {(selectedMenu === sideMenuData.INITIAL ||
//             selectedMenu === sideMenuData.COMP) && (
//             <Competition
//               handleGotoInitial={handleGotoInitial}
//               selectedMenu={selectedMenu}
//               setSelectedMenu={setSelectedMenu}
//               setShowRegion={setShowRegion}
//               setShowCompetion={setShowCompetion}
//               gameId={gameId}
//             />
//           )}
//         </>
//       )}
//     </div>
//   );
// };

// export default Sidebar;

//=====================================================================================

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { KeyProvider } from "../../KeyProvider";
import axios from "axios";
import ReactCountryFlag from "react-country-flag";
import { useCricket } from "../Cricket/CricketContext";
import { useFootball } from "../Football/FootballContext";
import { useHockey } from "../Hockey/HockeyContext";
import { useBaseball } from "../Baseball/BaseballContext";
import { useVolley } from "../Volleyball/VolleyContext";
import { useHandball } from "../Handball/HandballContext";
import { useBasketball } from "../Basketball/BasketballContext";

const Sidebar = () => {
  const [showLeague, setShowLeague] = useState({
    visible: false,
    selectedRegion: null,
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [areCategoriesVisible, setAreCategoriesVisible] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isRegionSelected, setIsRegionSelected] = useState(false);

  const getCategoryByRoute = (pathname) => {
    if (pathname.startsWith("/cricket")) return useCricket;
    if (pathname.startsWith("/football")) return useFootball;
    if (pathname.startsWith("/hockey")) return useHockey;
    if (pathname.startsWith("/basketball")) return useBasketball;
    if (pathname.startsWith("/volleyball")) return useVolley;
    if (pathname.startsWith("/baseball")) return useBaseball;
    if (pathname.startsWith("/handball")) return useHandball;
    return useCricket;
  };

  const activeContext = getCategoryByRoute(pathname);
  const { categories, resData } = activeContext() || {};

  const fetchSportsData = async (uniqueTournamentsId) => {
    const defaultUrl =
      "https://os-sports-perform.p.rapidapi.com/v1/unique-tournaments";
    const options = {
      headers: {
        "X-RapidAPI-Key": KeyProvider,
        "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
      },
      params: { category_id: uniqueTournamentsId },
    };

    try {
      const response = await axios.get(defaultUrl, options);
      return response?.data?.data || [];
    } catch (error) {
      console.error(
        `Error fetching data for category ${uniqueTournamentsId}:`,
        error
      );
      return [];
    }
  };

  const handleLeagues = async (region) => {
    setIsLoading(true);
    setError(null);

    try {
      const data = await fetchSportsData(region.id);
      setShowLeague({ visible: true, selectedRegion: data });
      setAreCategoriesVisible(false);
      setIsRegionSelected(true);
    } catch (error) {
      console.error("Error fetching leagues:", error);
      setError("Failed to load data");
    } finally {
      setIsLoading(false);
    }
  };

  // const handleLeagueClick = (element) => {
  //   const uniqueTournamentsId = element?.id;
  //   const match = resData;
  //   console.log(match);
  //   const validPathnames = [
  //     "/football",
  //     "/hockey",
  //     "/basketball",
  //     "/volleyball",
  //     "/baseball",
  //     "/handball",
  //   ];

  //   if (validPathnames.includes(pathname)) {
  //     navigate(`${pathname}/league`, { state: { uniqueTournamentsId, match } });
  //   } else if (pathname === "" || pathname === "/") {
  //     navigate(`/cricket/league`, { state: { uniqueTournamentsId, match } });
  //   } else {
  //     navigate(`${pathname}/league`, { state: { uniqueCategoryId, match } });
  //   }
  // };

  const handleLeagueClick = (element) => {
    const uniqueTournamentsId = element?.id;
    const match = resData;
    // const uniqueTournamentId = resData.map(data => data?.tournament?.uniqueTournament?.id);
    const uniqueTournamentId = resData.map(data => data?.season?.id);
    // if (!match) {
    //   console.error("No matching tournament found");
    //   return;
    // }
  
  
    const validPathnames = [
      "/football",
      "/hockey",
      "/basketball",
      "/volleyball",
      "/baseball",
      "/handball",
    ];
  
    if (validPathnames.includes(pathname)) {
      navigate(`${pathname}/league`, { state: { uniqueTournamentsId, match } });
    } else if (pathname === "" || pathname === "/") {
      navigate(`/cricket/league`, { state: { uniqueTournamentsId, match } });
    } else {
      navigate(`${pathname}/league`, { state: { uniqueTournamentsId, match } });
    }
  };
  

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCategories = categories.filter((region) =>
    region.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleBackClick = () => {
    setIsRegionSelected(false);
    setAreCategoriesVisible(true);
    setShowLeague({ visible: false, selectedRegion: null });
  };

  return (
    <div className="col-12 countrys-search-container border border-secondary px-2">
      <section className="d-flex">
        <form className="example m-2 regionHover">
          <input
            type="text"
            placeholder="Search.."
            name="search"
            value={searchQuery}
            onChange={handleSearchChange}
            className="inputField"
          />
        </form>
        {isRegionSelected && (
          <button
            className="btn p-0 mx-1 fw-bold fs-6"
            onClick={handleBackClick}
          >
            <span>&lt;</span>
          </button>
        )}
      </section>
      {isLoading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      {areCategoriesVisible && (
        <section>
          {filteredCategories.map((region) => (
            <button
              key={region.id}
              onClick={() => handleLeagues(region)}
              className="w-100 btn regionHover d-flex align-items-center gap-2 p-3 my-2"
            >
              <ReactCountryFlag
                countryCode={region.alpha2}
                svg
                style={{ width: "25px", height: "15px" }}
                alt={`${region.flag} flag`}
              />
              <div className="text-start">{region.name}</div>
            </button>
          ))}
        </section>
      )}

      {showLeague.visible && showLeague.selectedRegion?.length > 0 && (
        <section className="w-100 mt-2">
          {showLeague.selectedRegion.map((region) =>
            region.uniqueTournaments?.map((element) => (
              <button
                key={element.id}
                onClick={() => handleLeagueClick(element)}
                className="btn btn-sm w-100 p-3 my-2 mx-0 text-start text-white card-body"
              >
                <ReactCountryFlag
                  countryCode={element?.category?.alpha2}
                  svg
                  style={{ width: "25px", height: "15px" }}
                  alt={`${element?.category?.flag} flag`}
                />
                {element.name}
                <br />
              </button>
            ))
          )}
        </section>
      )}
    </div>
  );
};

export default Sidebar;
