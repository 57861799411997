import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Sidebar from "./Sidebar/Sidebar";
import Favourites from "./Favourites/Favourites";
import News from "./News/News";
import CricketIndex from "./Cricket/Index";
import FootballIndex from "./Football/Index";
import HockeyIndex from "./Hockey/Index";
import BasketIndex from "./Basketball/Index";
import VolleyIndex from "./Volleyball/Index";
import BaseballIndex from "./Baseball/Index";
import HandballIndex from "./Handball/Index";
import Cricket from "./Cricket/Cricket";
import { CricketProvider } from "./Cricket/CricketContext";
import { FootballProvider } from "./Football/FootballContext";
import { HockeyProvider } from "./Hockey/HockeyContext";
import { BasketballProvider } from "./Basketball/BasketballContext";
import { VolleyProvider } from "./Volleyball/VolleyContext";
import { BaseballProvider } from "./Baseball/BaseballContext";
import { HandballProvider } from "./Handball/HandballContext";
import Error from "./Error";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedDate, setSelectedDate] = useState(() => {
    const savedDate = localStorage.getItem("selectedDate");
    return savedDate ? new Date(savedDate) : new Date();
  });

  const [showCalendar, setShowCalendar] = useState(false);
  const [isLive, setIsLive] = useState(() => {
    const savedIsLive = localStorage.getItem("isLive");
    return savedIsLive ? JSON.parse(savedIsLive) : false;
  });

  useEffect(() => {
    localStorage.setItem("selectedDate", selectedDate.toISOString());
  }, [selectedDate]);

  useEffect(() => {
    localStorage.setItem("isLive", JSON.stringify(isLive));
  }, [isLive]);

  useEffect(() => {
    const gameRoutes = [
      "/cricket",
      "/football",
      "/hockey",
      "/basketball",
      "/volleyball",
      "/baseball",
      "/handball",
    ];
    const isGameRoute = gameRoutes.some((route) =>
      location.pathname.startsWith(route)
    );

    if (location.pathname === "/") {
      const date = new Date();
      setSelectedDate(date);
      setIsLive(false);
    }

    if (
      location.pathname === "/football" ||
      location.pathname === "/hockey" ||
      location.pathname === "/basketball" ||
      location.pathname === "/volleyball" ||
      location.pathname === "/baseball" ||
      location.pathname === "/handball"
    ) {
      const date = new Date();
      setSelectedDate(date);
      setIsLive(false);
    } else if (
      isGameRoute &&
      !(location.pathname === "/cricket/live") &&
      !(location.pathname === "/football/live") &&
      !(location.pathname === "/hockey/live") &&
      !(location.pathname === "/basketball/live") &&
      !(location.pathname === "/volleyball/live") &&
      !(location.pathname === "/baseball/live") &&
      !(location.pathname === "/handball/live")
    ) {
      setIsLive(false);
    }
  }, [location.pathname]);

  const handleButtonClick = () => {
    const date = new Date();
    setSelectedDate(date);
    setIsLive(true);
    const gameRoute = location.pathname.split("/")[1];
    if (
      gameRoute == "football" ||
      gameRoute == "hockey" ||
      gameRoute == "basketball" ||
      gameRoute == "volleyball" ||
      gameRoute == "baseball" ||
      gameRoute == "handball" ||
      !gameRoute == "/"
    ) {
      navigate(`/${gameRoute}/live`, { state: { date } });
    } else if (gameRoute == "") {
      navigate(`/cricket/live`, { state: { date } });
    } else {
      navigate(`/${gameRoute}/live`, { state: { date } });
    }
  };

  const handleClickDate = (date) => {
    setSelectedDate(date);
    setIsLive(true);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    const gameRoute = location.pathname.split("/")[1];

    if (
      gameRoute == "cricket" ||
      gameRoute == "football" ||
      gameRoute == "hockey" ||
      gameRoute == "basketball" ||
      gameRoute == "volleyball" ||
      gameRoute == "baseball" ||
      gameRoute == "handball"
    ) {
      navigate(`/${gameRoute}/${formattedDate}`, { state: { date } });
    } else {
      navigate(`/cricket/${formattedDate}`, { state: { date } });
    }
    setShowCalendar(false);
  };

  const generateDates = () => {
    const today = new Date();
    const dates = [];
    for (let i = -2; i <= 2; i++) {
      const newDate = new Date(today);
      newDate.setDate(today.getDate() + i);
      dates.push(newDate);
    }
    return dates;
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "long",
    });
  };

  const dates = generateDates();

  return (
    <div className="container d-flex mt-2 col-12">
      {/* Sidebar component */}
      <div className="col-mb-2 t-hide mt-5">
        {location.pathname.startsWith("/handball") && (
          <HandballProvider selectedDate={selectedDate}>
            <Sidebar />
          </HandballProvider>
        )}

        {location.pathname.startsWith("/hockey") && (
          <HockeyProvider selectedDate={selectedDate}>
            <Sidebar />
          </HockeyProvider>
        )}

        {location.pathname.startsWith("/basketball") && (
          <BasketballProvider selectedDate={selectedDate}>
            <Sidebar />
          </BasketballProvider>
        )}

        {location.pathname.startsWith("/cricket") && (
          <CricketProvider selectedDate={selectedDate}>
            <Sidebar />
          </CricketProvider>
        )}

        {location.pathname === "/" && (
          <CricketProvider selectedDate={selectedDate}>
            <Sidebar />
          </CricketProvider>
        )}

        {location.pathname.startsWith("/baseball") && (
          <BaseballProvider selectedDate={selectedDate}>
            <Sidebar />
          </BaseballProvider>
        )}

        {location.pathname.startsWith("/football") && (
          <FootballProvider selectedDate={selectedDate}>
            <Sidebar />
          </FootballProvider>
        )}

        {location.pathname.startsWith("/volleyball") && (
          <VolleyProvider selectedDate={selectedDate}>
            <Sidebar />
          </VolleyProvider>
        )}
      </div>

      {/* Main content */}
      <div className="main col-10">
        <div className="d-flex justify-content-center">
          <div className="sports-details-main-container p-4">
            <div className="days-container">
              <button
                type="button"
                className="btn btn-sm p-2"
                style={
                  isLive
                    ? {
                        backgroundColor: "#ff6b00",
                        color: "white",
                      }
                    : {}
                }
                onClick={handleButtonClick}
              >
                LIVE
              </button>
              {dates.map((date, index) => (
                <button
                  type="button"
                  className="btn btn-sm p-2"
                  style={
                    selectedDate.getDate() === date.getDate() && !isLive
                      ? {
                          backgroundColor: "#ff6b00",
                          color: "white",
                        }
                      : {}
                  }
                  key={index}
                  onClick={() => handleClickDate(date)}
                >
                  <div style={{ fontSize: "x-small" }}>
                    {index === 2
                      ? "TODAY"
                      : date
                          .toLocaleDateString("en-US", {
                            weekday: "short",
                          })
                          .toUpperCase()}
                  </div>
                  <div style={{ fontSize: "x-small" }}>{formatDate(date)}</div>
                </button>
              ))}
              {/* Calendar button */}
              <button
                type="button"
                className="btn btn-sm p-2 calendar-button"
                // onClick={() => setShowCalendar(showCalendar)}
                data-bs-toggle="modal"
                data-bs-target="#calendarModal"
              >
                <FaCalendarAlt className="calendar-icon" />
              </button>
            </div>
            <hr />
            {/* Calendar Modal */}
            <div
              className="modal fade"
              id="calendarModal"
              tabIndex="-1"
              aria-labelledby="calendarModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <Calendar
                    onChange={(date) => {
                      setSelectedDate(date);
                      handleClickDate(date);
                    }}
                    value={selectedDate}
                    className="calendar"
                  />
                </div>
              </div>
            </div>
            {/* Routes */}
            <div>
              <Routes>
                <Route
                  path="/"
                  element={
                    <CricketProvider selectedDate={selectedDate}>
                      <Cricket />
                    </CricketProvider>
                  }
                />
                <Route
                  path="/cricket/*"
                  element={
                    <CricketProvider selectedDate={selectedDate}>
                      <CricketIndex />
                    </CricketProvider>
                  }
                />
                <Route
                  path="/football/*"
                  element={
                    <FootballProvider selectedDate={selectedDate}>
                      <FootballIndex />
                    </FootballProvider>
                  }
                />
                <Route
                  path="/hockey/*"
                  element={
                    <HockeyProvider selectedDate={selectedDate}>
                      <HockeyIndex />
                    </HockeyProvider>
                  }
                />
                <Route
                  path="/basketball/*"
                  element={
                    <BasketballProvider selectedDate={selectedDate}>
                      <BasketIndex />
                    </BasketballProvider>
                  }
                />
                <Route
                  path="/volleyball/*"
                  element={
                    <VolleyProvider selectedDate={selectedDate}>
                      <VolleyIndex />
                    </VolleyProvider>
                  }
                />
                <Route
                  path="/baseball/*"
                  element={
                    <BaseballProvider selectedDate={selectedDate}>
                      <BaseballIndex />
                    </BaseballProvider>
                  }
                />
                <Route
                  path="/handball/*"
                  element={
                    <HandballProvider selectedDate={selectedDate}>
                      <HandballIndex />
                    </HandballProvider>
                  }
                />
                {/* <Route path="*" element={<Error />} /> */}
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
