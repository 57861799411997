import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import countryFlag from "../../../assets/India_Flag.png";
import BaseSummary from "./BaseSummary";
import ReactCountryFlag from "react-country-flag";
import BaseInfo from "./BaseInfo";
import PointTable from "./PointTable";
import LineUps from "./LineUps";
// import LineUps from "./LineUps";

const BaseResult = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("la") || "Info"
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    localStorage.setItem("la", activeTab);

    return () => {
      localStorage.removeItem("la");
    };
  }, [activeTab]);

  const handleRoute = () => {
    navigate("/baseball");
  };

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const convertTimestampToTimeOnly = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const timeString = dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return timeString;
  };

  return (
    <div>
      <div className="d-flex">
        <button
          onClick={handleRoute}
          className="btn btn-secondary w-100 d-flex align-items-center gap-2 mx-2 p-1"
          style={{ backgroundColor: "#111" }}
        >
          <ReactCountryFlag
            countryCode={match?.tournament?.category?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.tournament?.category?.country?.alpha3}
          />
          <div className="mx-2 text-start">
            <p>{match?.tournament?.name}</p>
            <p>{match?.tournament?.category?.country?.name}</p>
          </div>
        </button>
      </div>
      <hr />
      <div className="bg-dark mx-2 d-flex justify-content-evenly">
        <div className="text-center">
          <ReactCountryFlag
            countryCode={match?.homeTeam?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.homeTeam?.country?.alpha3}
          />
          <p className="p-2">
            {/* <img src={countryFlag} alt="flag" style={{width:'20px'}} /> */}
            {match?.homeTeam?.name}
          </p>
        </div>
        <div className="d-flex gap-1 align-items-center">
          {/* {match ? <p className="fsize">{convertTimestampToTimeOnly(match?.startTimestamp)}</p> : <p className="fsize">FT</p> } */}
          <p>{match?.homeScore?.display}</p>-<p>{match?.awayScore?.display}</p>
        </div>
        <div className="text-center">
          <ReactCountryFlag
            countryCode={match?.awayTeam?.country?.alpha2}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            title={match?.awayTeam?.country?.alpha3}
          />
          <p className="p-2">
            {/* <img src={countryFlag} alt="flag" style={{width:'20px'}} /> */}
            {match?.awayTeam?.name}
          </p>
        </div>
      </div>
      <hr />
      <div className="my-2 d-flex gap-2 mx-2 align-items-center">
        <Link
          to="/baseball/league/result/info"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Info" ? "active" : ""
          }`}
          onClick={() => handleClick("Info")}
        >
          Info
        </Link>
        <Link
          to="/baseball/league/result/summary"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Summary" ? "active" : ""
          }`}
          onClick={() => handleClick("Summary")}
        >
          Summary
        </Link>
        <Link
          to="/baseball/league/result/table"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Table" ? "active" : ""
          }`}
          onClick={() => handleClick("Table")}
        >
          Table
        </Link>
        {/* <Link
          to="/baseball/league/result/players"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Team" ? "active" : ""
          }`}
          onClick={() => handleClick("Team")}
        >
          Line-Ups
        </Link> */}
      </div>
      {activeTab === "Info" && <BaseInfo />}
      {activeTab === "Summary" && <BaseSummary />}
      {activeTab === "Table" && <PointTable />}
      {/* {activeTab === "Team" && <LineUps />} */}
    </div>
  );
};

export default BaseResult;
