import React, { useEffect, useState } from "react";
import FootOverview from "../Matches/FootOverview";
import FootMatchesHome from "../Matches/FootMatchesHome";
import Table from "../Table";
import { Link, useLocation, useNavigate } from "react-router-dom";
import countryFlag from "../../../assets/India_Flag.png";

const LeagueData = () => {
  const location = useLocation();
  const { match } = location.state || {};

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "Overview"
  );
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
    return () => {
      localStorage.removeItem("activeTab");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="card-title d-flex gap-2 px-2">
        <Link
          to="/football/league/overview"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "Overview" ? "active" : ""
          }`}
          onClick={() => handleClick("Overview")}
        >
          Overview
        </Link>
        <Link
          to="/football/league/match"
          state={{ match }}
          className={`link-eff btn btn-sm p-2 ${
            activeTab === "footmatch" ? "active" : ""
          }`}
          onClick={() => handleClick("footmatch")}
        >
          Matches
        </Link>
        {/* <Link
            to="/football/league/table"
            className={`link-eff btn btn-sm p-1 ${activeTab === "Table" ? "active" : ""}`}
            onClick={() => handleClick("Table")}
          >
            Table
          </Link> */}
      </div>
      {activeTab === "Overview" && <FootOverview />}
      {activeTab === "footmatch" && <FootMatchesHome />}
      {/* {activeTab === "Table" && <Table />} */}
      <br />
    </div>
  );
};

export default LeagueData;
