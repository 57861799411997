import React, { useEffect, useState } from "react";
import VolleyFutureMatch from "./VolleyFutureMatch";
import VolleyOlderMatch from "./VolleyOlderMatch";
import { Link, useLocation } from "react-router-dom";

const VolleyMatches = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("volMatch") || "Futures"
  );
  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    localStorage.setItem("volMatch", activeTab);

    return () => {
      localStorage.removeItem("volMatch");
    };
  }, [activeTab]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="px-4">
      <div className="my-2 d-flex gap-2">
        <Link
          to="/volleyball/league/match/future"
          state={{ match }}
          className={`btn btn-sm p-2 ${
            activeTab === "Futures" ? "active" : ""
          }`}
          onClick={() => handleClick("Futures")}
        >
          Futures
        </Link>
        <Link
          to="/volleyball/league/match/result"
          state={{ match }}
          className={`btn btn-sm p-2 ${
            activeTab === "Results" ? "active" : ""
          }`}
          onClick={() => handleClick("Results")}
        >
          Results
        </Link>
      </div>
      {activeTab === "Futures" && <VolleyFutureMatch />}
      {activeTab === "Results" && <VolleyOlderMatch />}
    </div>
  );
};

export default VolleyMatches;
