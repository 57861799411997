import React from 'react'

const Footer = () => {
  return (
    <div className='text-center text-secondary mt-5'>
        <p>&copy; 2024 livescore, All rights reserved.</p>
    </div>
  )
}

export default Footer