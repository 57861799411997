import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const FutureMatches = () => {
  const location = useLocation();
  const { match } = location.state || {};

  const navigate = useNavigate();

  const handleClick = (match) => {
    navigate("/cricket/league/today", { state: { match } });
  };

  const convertTimestampToDate = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    return dateObject.toLocaleString();
  };

  return (
    <div className="">
      <div className="fs-5 text-secondary">Future Matches</div>
      {match
        ?.filter((match) => match.status.code === 0)
        .map((match, index) => (
          <button
            key={index}
            className="card-body btn btn-sm w-100 mb-2 text-start text-white"
            onClick={() => handleClick(match)}
          >
            <span className="fsize">{match.tournament.name}</span>
            <br />
            <p className="fsize">
              {convertTimestampToDate(match.startTimestamp)} -{" "}
              {match.status.description}
            </p>
            <h6>{match.awayTeam.name}</h6>
            <h6>{match.homeTeam.name}</h6>
            <h6 className="fsize">{match.note}</h6>
          </button>
        ))}
    </div>
  );
};
