import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { KeyProvider } from "../../KeyProvider";

const Players = () => {
  const [resData, setResData] = useState([]);
  const date = new Date().toISOString().split("T")[0];
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const { match } = location.state || {};

  useEffect(() => {
    const getSportsData = async () => {
      const defaultUrl =
        "https://os-sports-perform.p.rapidapi.com/v1/events/innings";
      const defaultParams = {
        event_id: match.id,
      };

      const options = {
        headers: {
          "X-RapidAPI-Key": KeyProvider,
          "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.get(defaultUrl, {
          params: defaultParams,
          headers: options.headers,
        });

        setResData(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching Scorecard data:", error);
      }
    };

    getSportsData();
  }, [date]);

  return (
    <div className="d-flex justify-content-around mt-4">
      {isLoading ? (
        <div
          className="spinner-grow"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      ) : (
        resData.map((inning, index) => (
          <div key={index} className="text-left">
            <h5>{inning.battingTeam.name}</h5>
            <ul className="fsize p-0">
              {inning.battingLine.map((player, idx) => (
                <li key={idx}>{player.player.name}</li>
              ))}
            </ul>
          </div>
        ))
      )}
    </div>
  );
};

export default Players;
