import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Info from "../Info";
import PointTable from "../PointTable";

export const TodaysMatch = () => {
  const [activeTab, setActiveTab] = useState("Info");
  const navigate = useNavigate();
  const location = useLocation();
  const { match } = location.state || {};

  const handleRoute = () => {
    navigate("/cricket");
  };

  const convertTimestampToDate = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const dateObject = new Date(milliseconds);
    const dateString = dateObject.toISOString().split("T")[0];
    const timeString = dateObject.toTimeString().split(" ")[0];
    return `${dateString} ${timeString}`;
  };

  return (
    <div>
      <div>
        <div className="d-flex">
          <button
            onClick={handleRoute}
            className="btn btn-secondary w-100 d-flex align-items-center gap-2 mx-2 p-1"
            style={{ backgroundColor: "#111" }}
          >
            <div className="mx-2 text-start">
              <div>{match.tournament.name}</div>
              <div>{match.tournament.country}</div>
            </div>
          </button>
        </div>
        <hr />
        <div className="px-4 pb-2">
          <h6 className="mx-2">Today's Match</h6>
          <div>
            <span className="fsize">{match.tournament.name}</span>
            <br />
            <span className="fsize">
              {convertTimestampToDate(match.startTimestamp)} -{" "}
              {match.status.description}
            </span>
            <h6 className="text-white">{match.homeTeam.name}</h6>
            <h6 className="text-white">{match.awayTeam.name}</h6>
            <h6 className="fsize">
              {match.note || "Teams will be announced at the toss"}
            </h6>
          </div>
          <div className="d-flex gap-2 mt-3">
            <button
              className={`link-eff btn btn-sm p-2 mx-2 ${activeTab === "Info" ? "active" : ""
                }`}
              onClick={() => setActiveTab("Info")}
            >
              Info
            </button>
            <button
              className={`link-eff btn btn-sm p-2 ${activeTab === "Table" ? "active" : ""
                }`}
              onClick={() => setActiveTab("Table")}
            >
              Table
            </button>
            <button
              className={`link-eff btn btn-sm p-2 ${activeTab === "Team" ? "active" : ""
                }`}
              onClick={() => setActiveTab("Team")}
            >
              Teams
            </button>
          </div>
          {activeTab === "Info" && <Info tournamentData={match} />}
          {activeTab === "Table" && <PointTable tournamentData={match} />}
          {activeTab === "Team" && (
            <h6 className="fsize mt-3">Teams will be announced at the toss</h6>
          )}
        </div>
      </div>
    </div>
  );
};
