import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Component/Home";
import Games from "./Component/Games";
import "./Component/style/cricket.css";
import "./Component/style/style.css";
import Footer from "./Component/Footer/Footer";
import Error from "./Component/Error";
import { useState } from "react";

function App() {
  const [gameId, setGameId] = useState("");

  return (
    <BrowserRouter>
      <Games setGameId={setGameId} />
      <Routes>
        <Route path="/*" element={<Home gameId={gameId} />} />
        {/* <Route path="*" element={<Error />} /> */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
