import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const FootScoreCard = () => {
  const location = useLocation();
  const { match } = location.state || {};

  const [activeSection, setActiveSection] = useState("events");
  const handleEventsClick = () => {
    setActiveSection("events");
  };

  const handleCommentaryClick = () => {
    setActiveSection("commentary");
  };

  return (
    <div className="small-font">
      <section className="d-flex gap-4 mx-2">
        <button
          className={`link-eff btn btn-sm p-2 ${activeSection === "events" ? "active" : ""}`}
          onClick={handleEventsClick}
        >
          EVENTS
        </button>
        <button
          className={`link-eff btn btn-sm p-2 ${
            activeSection === "commentary" ? "active" : ""
          }`}
          onClick={handleCommentaryClick}
        >
          COMMENTARY
        </button>
      </section>
      <section>
        {activeSection === "events" && (
          <div>
            <section className="m-2">
              <table className="table table-dark border border-secondary p-2 border-opacity-25">
                <thead>
                  <tr>
                    <th>dsgfdgd</th>
                    <th>dsgfdgd</th>
                    <th>dsgfdgd</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{match.awayTeam.name}</td>
                    <td>{match.awayTeam.name}</td>
                    <td>{match.awayTeam.name}</td>
                  </tr>
                </tbody>
                  {/* {data.map((row, index) => (
                    <tr key={index}>
                    <tr>
                      <td>{row.minute}</td>
                      <td className="fsize">
                        <div>{row.player1}</div>
                        <div>{row.player2}</div>
                      </td>
                      <td>{row.score1}</td>
                      <td>{row.score2}</td>
                    </tr>
                  ))} */}
              </table>
            </section>
          </div>
        )}
        {/* {activeSection === "commentary" && (
          <div className="m-2">
            {commentaryData.map((comment, index) => (
              <div key={index} className="d-flex gap-4 border border-secondary p-2 border-opacity-25">
                <p>{comment.time}</p>
                <p>{comment.text}</p>
              </div>
            ))}
          </div>
        )} */}
      </section>
    </div>
  );
};

export default FootScoreCard;
