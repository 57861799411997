import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { KeyProvider } from "../../../KeyProvider";

const PointTable = () => {
  const [resData, setResData] = useState([]);
  const location = useLocation();
  const { match } = location.state || {};
  const date = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const getSportsData = async () => {
      const defaultUrl =
        "https://os-sports-perform.p.rapidapi.com/v1/seasons/standings";
      const defaultParams = {
        date: date,
        standing_type: "total",
        seasons_id: match.season.id,
        unique_tournament_id: match.tournament?.uniqueTournament?.id,
      };
      const options = {
        headers: {
          "X-RapidAPI-Key": KeyProvider,
          "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
        },
      };
      try {
        const response = await axios.get(defaultUrl, {
          params: defaultParams,
          headers: options.headers,
        });
        setResData(response.data.data);
      } catch (error) {
        console.error("Error fetching sports data:", error);
      }
    };
    getSportsData();
  }, [date]);

  return (
    <div className="p-3">
      {resData.length > 0 ? (
        resData.map((group, groupIndex) => (
          <div key={groupIndex} className="p-3">
            <h4 className="text-secondary">{group.name}</h4>
            <table border="1" className="table table-dark mx-3">
              <thead>
                <tr>
                  <th scope="col" className="text-secondary">
                    #
                  </th>
                  <th scope="col" className="text-secondary">
                    Team
                  </th>
                  <th scope="col" className="text-secondary">
                    M
                  </th>
                  <th scope="col" className="text-secondary">
                    W
                  </th>
                  <th scope="col" className="text-secondary">
                    D
                  </th>
                  <th scope="col" className="text-secondary">
                    L
                  </th>
                  <th scope="col" className="text-secondary">
                    F / SF
                  </th>
                  <th scope="col" className="text-secondary">
                    A / SA
                  </th>
                  {/* <th scope="col" className="text-secondary">SF</th>
                        <th scope="col" className="text-secondary">SA</th> */}
                  <th scope="col" className="text-secondary">
                    P
                  </th>
                </tr>
              </thead>
              <tbody>
                {group.rows.map((team, teamIndex) => (
                  <tr key={teamIndex}>
                    <th scope="row" className="text-secondary">
                      {team.position}
                    </th>
                    <td>
                      {/* <span>
                              <ReactCountryFlag
                              countryCode={team.tournament?.uniqueTournament?.category?.flag}
                              svg
                              style={{
                                width: "1.5em",
                                height: "1.5em",
                              }}
                              title={team.tournament?.uniqueTournament?.category?.flag}
                            />
                            </span> */}
                      {team.team.name}
                    </td>
                    <td>{team.matches}</td>
                    <td>{team.wins}</td>
                    <td>{team.draws}</td>
                    <td>{team.losses}</td>
                    <td>
                      {team.scoresFor} / {team.secondaryScoresFor}
                    </td>
                    <td>
                      {team.scoresAgainst} / {team.secondaryScoresAgainst}
                    </td>
                    {/* <td>{team.secondaryScoresFor}</td>
                          <td>{team.secondaryScoresAgainst}</td> */}
                    <td>{team.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))
      ) : (
        <div
          className="spinner-grow"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      )}
    </div>
  );
};

export default PointTable;
