import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { KeyProvider } from '../../../KeyProvider';

const LineUps = () => {
    const location = useLocation();
    const { match } = location.state || {};
    const [players, setPlayers] = useState({});
  
    useEffect(() => {
      const getSportsData = async () => {
        const defaultUrl =
          "https://os-sports-perform.p.rapidapi.com/v1/events/lineups";
        const defaultParams = { event_id: match.id };
        const options = {
          headers: {
            "X-RapidAPI-Key": KeyProvider,
            "X-RapidAPI-Host": "os-sports-perform.p.rapidapi.com",
          },
        };
        try {
          const response = await axios.get(defaultUrl, {
            params: defaultParams,
            headers: options.headers,
          });
          setPlayers(response.data.data);
        } catch (error) {
          console.error("Error fetching sports data:", error);
        }
      };
      if (match?.id) {
        getSportsData();
      }
    }, [match?.id]);
  
    return (
      <>
        <div className="d-flex justify-content-evenly">
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    {players.home?.players?.map((player, index) => (
                      <div
                        key={index}
                        style={{ marginBottom: "10px" }}
                        className="fsize card-body"
                      >
                        <strong>Name:</strong>{" "}
                        {player.player.name || player.player.shortName}
                        <br />
                        <strong>Substitute:</strong>{" "}
                        {player.substitute ? "Yes" : "No"}
                        <br />
                      </div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
  
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    {players.away?.players?.map((player, index) => (
                      <div
                        key={index}
                        style={{ marginBottom: "10px" }}
                        className="fsize card-body"
                      >
                        <strong>Name:</strong>{" "}
                        {player.player.name || player.player.shortName}
                        <br />
                        <strong>Substitute:</strong>{" "}
                        {player.substitute ? "Yes" : "No"}
                      </div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
}

export default LineUps